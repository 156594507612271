import React from 'react';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import {
    Button,
    Modal
  } from "reactstrap";


const IdleTimeOutModal = ({showModal, handleClose, handleLogout, remainingTime}) => {
    return (
        <Modal
            isOpen={showModal}
            toggle={handleClose}
            modalClassName="modal-register"
        >
            <div className="modal-header no-border-header text-center">
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={handleClose}
                    style={{color:"white"}}
                >
                    <span aria-hidden={true}>×</span>
                </button>
                <h4 className="" style={{ fontWeight: 'bold', fontSize:'15pt' }}>You Will Get Timed Out. You want to stay?</h4> 
            </div>
            
            <div className="modal-footer no-border-footer" style={{padding: '10px', backgroundColor:"#333333"}}>
                <Button variant="danger" onClick={handleLogout} className="btn-round" style={{marginTop:'5px'}}>
                    Logout
                </Button>
                <Button variant="primary" onClick={handleClose} className="btn-round bgcolorgold" style={{marginTop:'5px',  border:"none", color:"black"}}>
                    Stay
                </Button>
            </div>
        </Modal>
        
    )
}
export default IdleTimeOutModal;