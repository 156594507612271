import React from 'react';
import { Link } from 'react-router-dom';
class Footer extends React.Component {
    render() {
        var footerstyle = {padding:"0px 0",paddingBottom:'0'};  
        return (
            <footer id="footer" className="footer1" style={footerstyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 margin-t-15">
                            <h4 style={{ fontWeight:"bolder" }}>FOLLOW US ON</h4>
                            <ul className="list-inline social margin-t-10">
                                <li className="list-inline-item">
                                    <a href="https://www.facebook.com/Naresy-International-Education-Consultant-578610318865001/" target="__blank" className="social-icon"><i className="fa fa-facebook"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://twitter.com/NIEC_Indonesia" target="__blank" className="social-icon"><i className="fa fa-twitter"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.instagram.com/niec_indonesia/" target="__blank" className="social-icon"><i className="fa fa-instagram"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.youtube.com/channel/UCA5qiOgJdfMvADpyU7Oz7fQ/" target="__blank" className="social-icon"><i className="fa fa-youtube"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 margin-t-15 contact-us">
                            <h4 style={{ fontWeight:"bolder", paddingBottom:'10px' }}>CONTACT US</h4>
                            <ul>
                                <li>
                                    <label className="text-muted text-white" style={{ fontWeight:"bolder" }}>Phone : </label>
                                    <a href="tel:085643036194" className="text-white" style={{ fontWeight:"bolder" }}> 081238431234</a><br/>
                                    {/* <label style={{color:'#282828'}}>Phone : </label>
                                    <a href="tel:081316385766" className="text-white"> 0813 1638 5766</a> */}
                                </li>
                                <li>
                                    <label className="text-muted text-white" style={{ fontWeight:"bolder" }}>Email : </label>
                                    <a className="fontcolorgold" href="mailto:info@niecindonesia.com" style={{ fontWeight:"bolder" }}> info@niecindonesia.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container text-muted footer-bottom text-white" style={{ paddingTop:'20px', paddingBottom:"20px", fontWeight:"bolder" }}>
                    2019 | <a style={{ fontWeight:"bolder" }} className="fontcolorgold" href="http://niecindonesia.com/">NIEC Indonesia</a>
                </div>
            </footer>
        );
    }
}
export default Footer;