import React , { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
    Input,
    Row,
    Col,
    FormGroup,
    Label,
  } from "reactstrap";
//   import ReactPixel from 'react-facebook-pixel';
//import SweetAlert from 'sweetalert2-react';
import API_URL from  './../../../ApiUrl';
import SweetAlert from 'sweetalert2-react';

class InfoSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
        id_member: this.props.dataUser[0].id_member,
        nama: this.props.dataUser[0].nama_lengkap,
        notelp: this.props.dataUser[0].no_wa,
        email: this.props.dataUser[0].email,
        kotaAsal: this.props.dataUser[0].kode_member,
        rekomendasi:"1",
        kategori:"3",
        sekolah: this.props.dataUser[0].nama_sekolah,
        kelas:this.props.dataUser[0].kelas_semester,
        tipePeserta: "4",
        checkemail:"",
        pesanEmailError:"",

        id_propinsi:"",
        id_kabkota:"",
        dataPropinsi: [],
        dataKabkota: [],
        dataKabkotafilter:[],

        message:"Error !",
        error: {},
        formAlert:{display:'none'},
        formAlertClassName:'alert alert-warning',
        inputBorder: "border:1px solid #ca0b0b;",

        idParent : "",
        dataKategori: [],
        dataTipepeserta: [],
        dataRekomendasi: [],
        kodeMember: "",
        checkkodemember: "",
        pesanKodeMember:"",
        showSwal: false,
        hide:"",

        btnDisabled: false
    };
  }

    registrationSubmit(e){
        e.preventDefault();
        this.sendData();  
    }

    
    hideAlert(){
        setTimeout(
            function() {
                this.setState({message: "",formAlert:{display:'none',transition:'0.5s ease'}});
            }
            .bind(this),
            5000
        );  
    }

      checkEmail(){
        const url = API_URL.vexpo+'/peserta/cekmail';

        var formData = new FormData();
        formData.append('email',this.state.email);
        axios({
            method: 'post',
            url: url,
            data:formData,
            config:{headers:{'Content-Type': 'multipart/form-data'}}
        }).then(response => response.data)
        .then((data) => {
            this.setState({ checkemail: data[0].status, pesanEmailError: data[0].pesan })
            // console.log('cek data email', this.state.checkemail)
            // console.log('cek pesan email', this.state.pesanEmailError)
            console.log('cek email1', this.state.pesanEmailError)
        })
      }

    componentDidMount() {
        this.checkEmail();
    }

    sendData(){
      this.setState({
          message:"Proses simpan, mohon menunggu beberapa saat.",
          formAlert:{display:'block'},
          formAlertClassName:'alert alert-warning',
          btnDisabled: true
      });

        const url = API_URL.infosession+'/peserta/registrasi';
        
        var formData = new FormData();
        formData.append('nama_lengkap',this.state.nama);
        formData.append('no_wa',this.state.notelp);
        formData.append('email',this.state.email);
        formData.append('sekolah_kampus',this.state.sekolah);
        formData.append('kelas_semester',this.state.kelas);
        formData.append('id_jadwal', 90);
        formData.append('kota_asal',this.state.kotaAsal);

        axios({
            method: 'post',
            url: url,
            data:formData,
            config:{headers:{'Content-Type': 'multipart/form-data'}}
        }).then((response)=>{
            console.log('testing',response.data);
            // if(response.data[0].id_register !== 0){
            if(response.data === "ok"){
                this.hideAlert();
                this.setState({btnDisabled:false, showSwal:true})
            }else{
                this.setState({
                    // message:"Terjadi error di server! (102)",
                    message:response.data,
                    formAlert:{display:'block'},
                    formAlertClassName:'alert alert-danger',
                    btnDisabled:false,
                });
            }
        }).catch(function(response){
            console.log(response);
            this.setState({
                message:"Terjadi error di server! (101)",
                formAlert:{display:'block'},
                formAlertClassName:'alert alert-danger'
            });
        })
    }

    render() {
        return (
            <section className="section" id="register" style={{backgroundColor:"transparent"}}>
            
            <div className="container">
                <div className="row">
                {/* <EdufestSchedule /> */}
                    <div className="col-lg-8 offset-lg-2">
                        <h3 className="section-title text-center">PENDAFTARAN PESERTA</h3>
                        <div className="section-title-border margin-t-20"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="custom-form mt-4 pt-4">
                            <form method="post" action="" name="contact-form" id="contact-form">
                                <div className="row">
                                    <div className="col-lg text-center">
                                    <Label style={{ color: 'white', fontWeight:'500'}}>NIEC Student Prioritas Member & Student Onshore Virtual Gathering.</Label><br />
                                    <Label style={{ color: 'white', fontWeight:'500'}}>Sabtu, 29 Agustus 2020</Label><br />
                                    <Label style={{ color: 'white', fontWeight:'500'}}> Pukul 10:00 Wita</Label><br />
                                    </div>
                                </div>

       
                                <div className="row" style={{marginBottom:"20px"}}>
                                    <div className="col-lg-12">
                                        <div id="message" className={this.state.formAlertClassName+" alert-dismissible"} style={this.state.formAlert}>
                                            {this.state.message}
                                        </div>
                                    </div>
                                </div>

                                <a href="/home" style={{color:'#00aeef', textDecoration:"underlined", display:this.state.hide}}>Kembali</a>
                                <div className="row">
                                    <div className="col-lg-12 text-right">
                                        <input disabled={this.state.btnDisabled} type="submit" id="submit" name="register" onClick={e=>this.registrationSubmit(e)} className="submitBnt btn btn-custom" value="Submit" />
                                        <div id="simple-msg"></div>
                                    </div>
                                </div>

                                <SweetAlert
                                    show={this.state.showSwal}
                                    title="Selamat"
                                    text="Anda Berhasil Mendaftar"
                                    icon= 'success'
                                    confirmButtonText="Kembali"
                                    onConfirm={() => 
                                    window.location.href="/home"
                                    }
                                />


                            </form>
                        </div>  
                    </div>
                </div>
            </div>
            
        </section> 
        );
    }
}
// export default Register;

const reduxState = (state) => ({
  dataUser: state.detailUser.user
})
 
 export default connect(reduxState)(InfoSession);
