// API PROD
// const url = "http://103.41.205.45:1935";
// const master = "http://103.41.205.45:1936";
// const appreciationnight = "http://103.41.205.45:1937";
// const masterKey = 'koibg3kyOxX73kKTNwCsbNXnocy6nMPQ';
// const appnightKey = 'zQaNJV7YHgxqkEg9GSA1Q8AT0VD59KEu';

// const API_URL = {
//     url,
//     master,
//     appreciationnight,
//     masterKey,
//     appnightKey
// }

// export default API_URL;
// END API PROD

// API DEV
// const url = "http://103.41.205.87:8020";
// const master = "http://103.41.205.87:1936";
// const appreciationnight = "http://103.41.205.87:1937";
// const masterKey = 'koibg3kyOxX73kKTNwCsbNXnocy6nMPQ';
// const appnightKey = 'zQaNJV7YHgxqkEg9GSA1Q8AT0VD59KEu';

// const API_URL = {
//     url,
//     master,
//     appreciationnight,
//     masterKey,
//     appnightKey
// }

// export default API_URL;
// END API DEV

// API DEMO
const url = "http://103.41.205.87:1939";
const master = "http://103.41.205.87:1936";
const appreciationnight = "http://103.41.205.87:1937";
const masterKey = 'koibg3kyOxX73kKTNwCsbNXnocy6nMPQ';
const appnightKey = 'zQaNJV7YHgxqkEg9GSA1Q8AT0VD59KEu';

const API_URL = {
    url,
    master,
    appreciationnight,
    masterKey,
    appnightKey
}

export default API_URL;
// END API DEMO