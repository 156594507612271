/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React , { Component } from 'react';
import {
 NavLink
} from 'react-router-dom';

// reactstrap components
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Button,
    Container,
    Row,
    Col,
    FormText
} from "reactstrap";
import classnames from "classnames";

// core components

function TransferPageContent() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        style={{
          //backgroundImage: "url(" + require("assets/img/daniel-olahh.jpg") + ")"
          backgroundColor: "#F6F4F2"
        }}
        className="page-header-register"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <div className="form-register" style= {{ marginTop:"100px" }}>
                <Row>
                    <Col>
                        <Row>
                            <Col md="6">
                                <Card className="card-plain">
                                    <CardBody>
                                        <div className="author">
                                            <h5>TRANSFER #1</h5>
                                        </div>
                                    
                                        <div className="form-row">
                                            <FormGroup className="col-md-6">                             
                                                <Input type="name"  id="inputName" placeholder="Nama"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="email"  id="inputEmail" placeholder="Email"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="kota"  id="inputKota" placeholder="Kab / Kota" autoComplete="off"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="provinsi"  id="inputProvinsi" placeholder="Provinsi" autoComplete="off"/>
                                            </FormGroup>
                                        </div>

                                            <FormGroup>
                                                <Input type="text"  id="inputPhone" placeholder="No Telepon / WA"/>
                                            </FormGroup> 

                                        <div className="form-row">
                                            <FormGroup className="col-md-6">                             
                                                <Input type="bank"  id="inputBank" placeholder="Nama Bank"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="noRekening"  id="inputNoRekening" placeholder="No Rekening"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="cabangBank"  id="inputCabangBank" placeholder="Kantor Cabang Bank" autoComplete="off"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="atasNama"  id="inputAtasNama" placeholder="Atas Nama Rekening" autoComplete="off"/>
                                            </FormGroup>
                                        </div>

                                        <div className="form-row">
                                            <FormGroup className="col-md-6">                             
                                                <Button size="lg" block type="submit" style={{ backgroundColor:"#E9BD43", border:"none" }}>UPLOAD BUKTI TRANSFER</Button>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Button size="lg" block type="submit" style={{ backgroundColor:"#E9BD43", border:"none" }}>MINTA VERIFIKASI</Button>
                                            </FormGroup>
                                        </div>                               
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="6">
                                <Card className="card-plain">
                                    <CardBody>
                                        <div className="author">
                                            <h5>TRANSFER #2</h5>
                                        </div>
                                    
                                        <div className="form-row">
                                            <FormGroup className="col-md-6">                             
                                                <Input type="name"  id="inputName" placeholder="Nama"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="email"  id="inputEmail" placeholder="Email"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="kota"  id="inputKota" placeholder="Kab / Kota" autoComplete="off"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="provinsi"  id="inputProvinsi" placeholder="Provinsi" autoComplete="off"/>
                                            </FormGroup>
                                        </div>

                                            <FormGroup>
                                                <Input type="text"  id="inputPhone" placeholder="No Telepon / WA"/>
                                            </FormGroup> 

                                        <div className="form-row">
                                            <FormGroup className="col-md-6">                             
                                                <Input type="bank"  id="inputBank" placeholder="Nama Bank"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="noRekening"  id="inputNoRekening" placeholder="No Rekening"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="cabangBank"  id="inputCabangBank" placeholder="Kantor Cabang Bank" autoComplete="off"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="atasNama"  id="inputAtasNama" placeholder="Atas Nama Rekening" autoComplete="off"/>
                                            </FormGroup>
                                        </div>

                                        <div className="form-row">
                                            <FormGroup className="col-md-6">                             
                                                <Button size="lg" block type="submit" style={{ backgroundColor:"#E9BD43", border:"none" }}>UPLOAD BUKTI TRANSFER</Button>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Button size="lg" block type="submit" style={{ backgroundColor:"#E9BD43", border:"none" }}>MINTA VERIFIKASI</Button>
                                            </FormGroup>
                                        </div>                               
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <Card className="card-plain">
                                    <CardBody>
                                        <div className="author">
                                            <h5>TRANSFER #3</h5>
                                        </div>
                                    
                                        <div className="form-row">
                                            <FormGroup className="col-md-6">                             
                                                <Input type="name"  id="inputName" placeholder="Nama"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="email"  id="inputEmail" placeholder="Email"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="kota"  id="inputKota" placeholder="Kab / Kota" autoComplete="off"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="provinsi"  id="inputProvinsi" placeholder="Provinsi" autoComplete="off"/>
                                            </FormGroup>
                                        </div>

                                            <FormGroup>
                                                <Input type="text"  id="inputPhone" placeholder="No Telepon / WA"/>
                                            </FormGroup> 

                                        <div className="form-row">
                                            <FormGroup className="col-md-6">                             
                                                <Input type="bank"  id="inputBank" placeholder="Nama Bank"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="noRekening"  id="inputNoRekening" placeholder="No Rekening"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="cabangBank"  id="inputCabangBank" placeholder="Kantor Cabang Bank" autoComplete="off"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="atasNama"  id="inputAtasNama" placeholder="Atas Nama Rekening" autoComplete="off"/>
                                            </FormGroup>
                                        </div>

                                        <div className="form-row">
                                            <FormGroup className="col-md-6">                             
                                                <Button size="lg" block type="submit" style={{ backgroundColor:"#E9BD43", border:"none" }}>UPLOAD BUKTI TRANSFER</Button>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Button size="lg" block type="submit" style={{ backgroundColor:"#E9BD43", border:"none" }}>MINTA VERIFIKASI</Button>
                                            </FormGroup>
                                        </div>                               
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="6">
                                <Card className="card-plain">
                                    <CardBody>
                                        <div className="author">
                                            <h5>TRANSFER #4</h5>
                                        </div>
                                    
                                        <div className="form-row">
                                            <FormGroup className="col-md-6">                             
                                                <Input type="name"  id="inputName" placeholder="Nama"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="email"  id="inputEmail" placeholder="Email"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="kota"  id="inputKota" placeholder="Kab / Kota" autoComplete="off"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="provinsi"  id="inputProvinsi" placeholder="Provinsi" autoComplete="off"/>
                                            </FormGroup>
                                        </div>

                                            <FormGroup>
                                                <Input type="text"  id="inputPhone" placeholder="No Telepon / WA"/>
                                            </FormGroup> 

                                        <div className="form-row">
                                            <FormGroup className="col-md-6">                             
                                                <Input type="bank"  id="inputBank" placeholder="Nama Bank"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="noRekening"  id="inputNoRekening" placeholder="No Rekening"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="cabangBank"  id="inputCabangBank" placeholder="Kantor Cabang Bank" autoComplete="off"/>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Input type="atasNama"  id="inputAtasNama" placeholder="Atas Nama Rekening" autoComplete="off"/>
                                            </FormGroup>
                                        </div>

                                        <div className="form-row">
                                            <FormGroup className="col-md-6">                             
                                                <Button size="lg" block type="submit" style={{ backgroundColor:"#E9BD43", border:"none" }}>UPLOAD BUKTI TRANSFER</Button>
                                            </FormGroup>

                                            <FormGroup className="col-md-6">
                                                <Button size="lg" block type="submit" style={{ backgroundColor:"#E9BD43", border:"none" }}>MINTA VERIFIKASI</Button>
                                            </FormGroup>
                                        </div>                               
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>           
                </Row>
          </div>
          
        </Container>
        {/* <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("./../../../assets/img/clouds.png") + ")"
          }}
        /> */}
      </div>
    </>
  );
}

export default TransferPageContent;
