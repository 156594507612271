/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import Cookies from 'universal-cookie';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
//import Datetime from 'react-datetime';
import SweetAlert from 'sweetalert2-react';
import { connect } from 'react-redux';
import Datetime from "react-datetime";
import Moment from "moment";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { StyleRoot } from 'radium';

import ReactDOM from 'react-dom';
import Coverflow from 'react-coverflow';

import { HomeHeader, MemberNavbar, DemoFooter, AppreciationNight, KonsultasiZoom } from './../../../components/molecules/';
import { grey } from "ansi-colors";
import classnames from "classnames";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { listBookingUpdate } from "./../../../config/redux/action"
import { Link } from "react-router-dom";

const cookies = new Cookies();
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_member:this.props.dataUser[0].id_member,
      email:this.props.dataUser[0].email,
      tanggal:"",
      clock:"",
      jam:"",
      id_kota:"",
      keterangan:"",
      dataKota:[],
      message:"Error !",
      error: {},
      showSwal: false,
      formAlert:{display:'none'},
      formAlertClassName:'alert alert-danger',
      inputBorder: "border: 1px solid #ca0b0b",
      active: 0,
      kode_member: this.props.dataUser[0].kode_member,
      regisAppreciationNight: false,
      regisIfosession: false,
      regisOpenhouse: false,
      btnDisabled: false
    };
  }

  
  handleValidation(){
    let errors = {};
    let formIsValid = true;
    //tanggal
    if(!this.state.tanggal){
        formIsValid = false;
        errors.tanggal = "Tanggal wajib diisi";
        this.inputTanggal.style = this.state.inputBorder;
    }
    //jam
    if(!this.state.jam){
        formIsValid = false;
        errors.jam = "Jam wajib diisi";
        this.inputJam.style = this.state.inputBorder;
    }
    //kota
    // if(this.state.id_kota === "0"){
    //   formIsValid = false;
    //   errors.kota = "Pilih kota terlebih dahulu";
    //   this.selectKota.style = this.state.inputBorder;
    // }
    //keterangan
    // if(!this.state.keterangan){
    //     formIsValid = false;
    //     errors.keterangan = "Keterangan wajib diisi";
    //     this.inputKeterangan.style = this.state.inputBorder;
    // }
    this.setState({error:errors});
    return formIsValid;
  }

  bookingSubmit(e){
    e.preventDefault();
    if(this.handleValidation()){
      this.sendData();
    }
  }

  sendData(){
    this.setState({
      message:"Proses simpan, mohon menunggu beberapa saat.",
      formAlert:{display:'block'},
      formAlertClassName:'alert alert-warning',
      btnDisabled: true
    });

    var formData = new FormData();
    formData.append('id_member',this.state.id_member);
    formData.append('tanggal',this.state.tanggal);
    formData.append('jam',this.state.jam);
    formData.append('id_kota',this.state.id_kota);
    formData.append('keterangan',this.state.keterangan);
    formData.append('email',this.state.email);
    axios({
      method: 'post',
      url:API_URL.url+'/tmbooking/save_tmbooking',
      data:formData,
      config:{headers:{'Content-Type': 'multipart/form-data'}}
    }).then((reply)=>{
        //console.log(reply)
        if(reply.data === "ok"){
          let update = this.props.updateList(this.state.id_member)
          this.setState({showSwal:true,btnDisabled: false })
        }else{
          alert(reply);
        }
    }).catch(function(reply){
        console.log(reply)
    });
  }

  onBlurJam(e){
    e.preventDefault()
    if(this.state.jam.length > 2){
        this.inputJam.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.jam = '';                                 
            return { error };                   
        })
    }
  }

  onBlurTanggal(e){
    e.preventDefault()
    if(this.state.tanggal !== "0"){
        this.inputTanggal.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.tanggal = '';                                 
            return { error };                   
        })
    }
  }
  onBlurKeterangan(e){
      e.preventDefault()
      if(this.state.keterangan.length > 0){
          this.inputKeterangan.style = "";
          this.setState(prevState => {
              let error = Object.assign({}, prevState.error);  
              error.keterangan = '';                                 
              return { error };                   
          })
      }
  }

  // onBlurKota(e){
  //   e.preventDefault()
  //   if(this.state.kota !== "0"){
  //       this.selectKota.style = "";
  //       this.setState(prevState => {
  //           let error = Object.assign({}, prevState.error);  
  //           error.kota = '';                                 
  //           return { error };                   
  //       })
  //   }
  // }

  // handleDate(tanggal){
  //   this.setState({tanggal:tanggal});
  //   console.log('tesdate', tanggal)
  // };
  

  handleDate = (tanggal) => {
    const date = Moment(tanggal).format('YYYY-MM-DD')
    this.setState({tanggal:date});
    console.log('tesdate', date)
  }

  handleClock = (jam) => {
    const clockDisplay = Moment(jam);
    this.setState({clock:clockDisplay});

    const jamsimpan = this.state.clock 
    const clock = Moment(jamsimpan).format('hh:mm:ss')
    
    this.setState({jam:clock});
    console.log('tesjam', this.state.jam)
    console.log('tesjam', this.state.clock._d)
  }

  //handleDate = (tanggal) => this.setState({ tanggal: tanggal });

  componentDidMount(){
    // this.checkIssetPayment();
    // this.handleDate();
    // this.handleClock();
    const payment = sessionStorage.getItem('status_payment');
    // alert(payment)
    if( payment === 'false' ){
        window.location.href="/infopayment";
      }
    const date = new Date();
    console.log('cekidkonsultan', this.props.dataKonsultan[0])
    console.log('cekiduser', this.props.dataUser[0])
    //console.log('tesdate', this.state.tanggal)
    this.getDataKota();
  }

  getDataKota(){
    const url = API_URL.url+'/kota';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
        }
    }).then(response => response.data)
    .then((data) => {
        this.setState({ dataKota: data })
        console.log(this.state.dataKota)
    })
  }
  radioChangeHandler = (event) =>{
    this.setState({
        id_kota:event.target.value
    })
  }


  // checkIssetPayment(){
  //   let lValid = true;
  //   const kode_member = this.state.kode_member;
  //   const url = API_URL.url+'/member/find_by_id/'+kode_member;
  //   axios.get(url,{
  //       headers: {
  //           'Accept': 'application/json',
  //       }
  //   }).then(response => response.data)
  //   .then((data) => {
  //       //this.setState({ dataLocations: data })
  //       console.log(data[0]);
  //       if(data[0].status_payment === "Y" && data[0].bukti_payment !== ""){
  //         return;
  //       }else{
  //         window.location.href = "/infopayment";          
  //       }
  //   });
  // }

  closeSwalError(){
    this.setState({
      email_forgot_password:"",
      email: "",
      password: "",
      showSwalError1:false
    })
    if(this.state.regisAppreciationNight){
      this.inputEmailForgotPassword.value = "";
    }else{
      this.inputEmail.value="";
      this.inputPassword.value="";
    }
  }

  onClickRegisAppreciationNight(){
    this.setState({
      regisAppreciationNight: true
    });
  }

  onClickRegisOpenhouse(){
    this.setState({
      regisOpenhouse: true
    });
  }

  onClickRegisInfosession(){
    this.setState({
      regisIfosession: true
    });
  }

  // clearInput() {
  //   this.inputNamaLengkap.value = "";
  //   this.inputNoTelp.value = "";
  //   this.inputEmail.value = "";
  //   this.inputNamaSekolahlah.value = "";
  //   this.inputKelasSemester.value = "";
  //   this.inputPassword.value = "";
  //   this.selectKonsultan.value = "";
  //   this.inputRePassword.value = "";
  //   this.inputKodePromo.value = "";
  // }
  
  render() {
    const fn = function () {
      /* do your action */
    }

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };
    const responsive2 = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 3, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 2, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };

    const responsiveBanner = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };

    
    // const noWa = `https://api.whatsapp.com/send?phone=${dataKonsultan.no_wa}&text=Saya ingin bertanya tentang sekolah keluar negeri.`
  
    var regisAppreciationNight = this.state.regisAppreciationNight;
    if(regisAppreciationNight){
      return (
          <div style={{
            backgroundColor: "#333"
          }}
          className=""
          data-parallax={true}>
            <Container>
              <Row>
                <Col className="mx-auto" lg="12" md="12">
                  <Card className="card-sudah-login" >
                    <AppreciationNight />
                    <br/>
                    {/* <a href="/home" style={{color:'#00aeef', textDecoration:"underlined"}}>Kembali</a> */}
  
                  </Card>
                </Col>
              </Row>
  
              <SweetAlert
              show={this.state.showSwalSuccess1}
              confirmBtnText="Kembali"
              confirmBtnBsStyle="default"
              success
              title="Berhasil!"
              text={this.state.showSwalSuccess_msg1}
              btnSize="md"
              onConfirm={() => 
                window.location.href='/'
              }
            >
            </SweetAlert>
  
            <SweetAlert
              show={this.state.showSwalError1}
              confirmBtnText="Kembali"
              confirmBtnBsStyle="default"
              danger
              title="Error!"
              text={this.state.showSwalError_msg1}
              btnSize="md"
              onConfirm={() => 
                this.closeSwalError()
              }
            >
            </SweetAlert>
            </Container>
          </div>
      );
    }else{
      return (
        <>
          <MemberNavbar />
          {/* <HomeHeader /> */}
          <div className="main">
            <div className="section page-header section-dark section-nucleo-icons" style={{ backgroundColor: 'black' }}>
              <Container>
                <Row>
                  <Col lg="6" md="12" style={{display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Row>
                      <Col md="12">
                        <h2 className="title1" style={{ fontWeight: 'bold' }}>Talent Mapping</h2>
                      </Col>
                      <Col md="12">
                        <h5 style={{ fontWeight: '400', color:'white' }}>
                          Talent Mapping adalah pemetaan bakat-minat dengan menggunakan tiga personality test 
                          dimana siswa dapat menemukan passion dan career suggestion yang tepat berdasarkan 
                          kepribadian dan potensi yang dimiliki. 
                        </h5>
                      </Col>
                    </Row>
                  </Col>
    
                  <Col lg="6" md="12">
                    <Row>
                      <Col md="12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img
                          alt="..."
                          src= {require(`./../../../assets/img/niec-logo/Cover_TM-01.png`)}
                          className="img-thumbnail img-no-padding img-responsive" style={{ backgroundColor:'transparent', width:"40%" }}
                        />
                      </Col>
                      <Col md="12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Link to={{ pathname: `http://dev.niecindonesia.com:9191/${this.props.dataUser[0].kode_member}/${this.props.dataUser[0].token_user}` }} target="_blank" >
                        {/* <Link to={{ pathname: `http://talentmapping.niecindonesia.com/${this.props.dataUser[0].kode_member}/${this.props.dataUser[0].token_user}` }} target="_blank" > */}
                          <Button className="text-center" style={{ backgroundColor:'#C39B3F'}}>Lakukan Test</Button>  
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>{" "}
    
          </div>
          <DemoFooter />
        </>
      );
    }
    
            }
}

//export default Home;


const reduxState = (state) => ({
  dataKonsultan: state.KonsultanMember,
  dataUser: state.detailUser.user
})

const reduxDispatch = (dispatch) => ({
  updateList: (data) => dispatch(listBookingUpdate(data))
 })
 
 export default connect(reduxState, reduxDispatch)(Home);
