import API from './../../services';

export const loginUserAPI = (data) => (dispatch) => {
    //dispatch({type: 'CHANGE_LOADING', value: true})
    return (

        API.login(data)
        .then((res) => {
                const detailUser = res.data;
                // const idKonsultan = res.data.user[0].id_konsultan;
                console.log('datauser', detailUser);
                if(detailUser.status_login === true){
                    dispatch({type: 'GET_DETAIL_USER', value: detailUser })

                    const idKonsultan = res.data.user[0].id_konsultan;
                    const codeKonsultan = res.data.user[0].code_konsultan;
                    const idMember = res.data.user[0].id_member;
                    API.getKonsultanMember(idKonsultan)
                    .then((res) => {
                        const konsultanMember = res;
                        console.log('konsultanmember', konsultanMember);
                        dispatch({type: 'GET_KONSULTAN_MEMBER', value: konsultanMember })
                    })

                    API.getListBooking(idMember)
                    .then((res) => {
                        const dataBooking = res;
                        dispatch({type: 'GET_LIST_BOOKING', value: dataBooking })
                    })  
                    
                    API.getCalendlyLink('CT010', 'TK002')
                    .then((res) => {
                        const dataCalendly = res;
                        dispatch({type: 'GET_CALENDLY_LINK', value: dataCalendly })
                    })
                }else{
                    dispatch({type: 'GET_DETAIL_USER', value: detailUser })
                    console.log('datauser2', detailUser);
                }

        })
        // .catch(function(error) {
        //     console.log('gagal:', error)
        // })
    )
}

export const logout = (data) => (dispatch) => {
    // return (
    //     dispatch({type: 'LOGOUT'})
    // )
    return (
        API.logout(data)
        .then((res) => {
            dispatch({type: 'LOGOUT' })
        })
    )
}

export const updateDataProfile = (data) => (dispatch) => {
    return (
        API.getDataProfile(data)
        .then((res) => {
            const dataProfile = res;
            dispatch({type: 'GET_DETAIL_USER', value: dataProfile })
        })
    )
}

export const listBookingUpdate = (data) => (dispatch) => {
    return (
        API.getListBooking(data)
        .then((res) => {
            const dataBooking = res;
            dispatch({type: 'GET_LIST_BOOKING', value: dataBooking })
        })
    )
}
