import React , { Component } from 'react';
import {
 NavLink
} from 'react-router-dom';

// reactstrap components
import ContactListingForm  from "./ContactListingForm";

// core components

function ComingSoonContent() {

  return (
    <>
      <div
        style={{
          backgroundColor: "#435664", marginTop: "100px"
        }}
        // className="page-header"
      >
    <div className="filter" />
        <ContactListingForm />
      </div>
    </>
  );
}

export default ComingSoonContent;
