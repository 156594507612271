import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
//untuk trace error di console
import logger from 'redux-logger';
import reducer from './../reducer';
import { persistStore, persistReducer } from 'redux-persist'
import storage from "redux-persist/es/storage";
import { composeWithDevTools } from 'redux-devtools-extension';


// const store = createStore(reducer, {}, applyMiddleware(thunk, logger));
//export default store;


const persistConfig = {
    key: "root",
    storage,
  };


const persistedReducer = persistReducer(persistConfig, reducer)

// export default () => {
//   let store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk, logger)))
//   let persistor = persistStore(store)
//   return { store, persistor }
// }



export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk, logger)));
