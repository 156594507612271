/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import axios from 'axios';
import {Link} from 'react-router-dom';
//import api url
import API_URL from  './../../../ApiUrl';
import Cookies from 'universal-cookie';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import SweetAlert from 'sweetalert2-react';
// core components
//import ExamplesNavbar from "./../components/molecules/Navbars/ExamplesNavbar.js";
//import LandingPageHeader from "./../components/molecules/Headers/LandingPageHeader.js";
//import DemoFooter from "./../components/molecules/Footers";

import { LandingPageHeader, ExamplesNavbar, DemoFooter, CardInfo } from './../../../components/molecules/';
import { grey } from "ansi-colors";
import classnames from "classnames";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const cookies = new Cookies();
class LandingPage extends Component {
  constructor(props) {
    super(props);
    //cek login
    const session = sessionStorage.getItem('status_login');
    if( session ){
      window.location.href="/home";
    }
    this.state = {
      kode_promo: "",
      nama_lengkap:"",
      no_telp:"",
      email:"",
      sekolah_kampus:"",
      // kelas_semester:"",
      konsultan:"0",
      password:"",
      re_password:"",
      id_harga:"",
      message:"Error !",
      error: {},
      showSwal: false,
      formAlert:{display:'none'},
      formAlertClassName:'alert alert-danger',
      inputBorder: "border: 1px solid #ca0b0b",
      dataKonsultan:[],
      dataKonsultanFilter:[],
      dataHarga:[],
      statusKode:"",
      btnDisabled: false,
      code_jenjang:"",
      code_kelas:"",

      id_propinsi:"",
      id_kabkota:"",
      dataPropinsi: [],
      dataKabkota: [],
      dataKabkotafilter:[],
      dataMinatBakat: [],
      minat_bakat:"",
      renderInputSekolah:"none",
      renderInputKet:"none",
      renderKonsultan:"none",
      dataJenPen: [],
      dataKelasSemester: [],
      dataKelasSemesterFilter:[],
      id_area:"",
      ket_lainnya:"",

      //emailExist:""
      //kode_member: base64.decode(cookies.get('kode_member'))
    };
  }

  
  onChangeKodePromo = (e) => {
    const idHarga = e.target.value;
    if(e.target.value != ""){
      //this.setState({ id_harga : idHarga });
      const kodePromo = e.target.value;
      const url = API_URL.url+'/harga/'+kodePromo;
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
            }
        }).then(response => response.data)
        .then((data) => {
            if(data.length !== '0'){
              // console.log('cekdataddd',data);
            this.setState({ dataHarga: data, statusKode: data[0].status, id_harga: idHarga})
            // console.log('cekdata',this.state);
            }else{
              this.setState({ dataHarga: '', statusKode: '',id_harga: '' })
            }
        }).catch(function(response){
          
          if(response.TypeError === undefined){
            // console.log(response)
            // console.log(this.state.dataHarga)
            this.setState({ dataHarga:'', statusKode: '', id_harga: ''})
          }else{
            this.setState({ dataHarga:'', statusKode: '', id_harga: ''})
          }
          //this.setState({ dataHarga:'', statusKode: ""})
          //console.log(this.state);
        }.bind(this));
    }else{
      this.setState({ dataHarga: '', statusKode: '', id_harga: '' })
    }
     
  }

  onMinatBakatChanged = (e) => { 
    const id = e.currentTarget.value;
    setTimeout(
      function() {
        this.setState(prevState => {
          let minat_bakat = Object.assign({}, prevState.minat_bakat);  
          minat_bakat = id;                                 
          return { minat_bakat };                   
        })
        // console.log('tes cange', this.state.minat_bakat)
      }
      .bind(this),
      1000
    );
  }

  handleValidation(){
    let errors = {};
    let formIsValid = true;
      if(this.state.dataHarga !== '' ){
        formIsValid = false;
        if(this.state.statusKode === 'N'){
          errors.id_harga = "Masa Berlaku Kode Telah Berakhir!";
          this.inputKodePromo.style = this.state.inputBorder;
        }else{
          formIsValid = true;
        }
        // console.log('cekcek',this.state);
      }else{
          formIsValid = false;
          this.setState({ statusKode: 'N' });
          errors.id_harga = "Kode Tidak Ditemukan!";
          //this.inputKodePromo.style = this.state.inputBorder;
      }

    if(!this.state.nama_lengkap){
        formIsValid = false;
        errors.nama_lengkap = "Nama wajib diisi";
        this.inputNamaLengkap.style = this.state.inputBorder;
    }
    //notelp
    if(!this.state.no_telp){
        formIsValid = false;
        errors.no_telp = "No. Telp wajib diisi";
        this.inputNoTelp.style = this.state.inputBorder;
    }
    //email
    if(!this.state.email){
        formIsValid = false;
        errors.email = "Email wajib diisi";
        this.inputEmail.style = this.state.inputBorder;
    }

    if(typeof this.state.email !== "undefined"){
        let lastAtPos  = this.state.email.lastIndexOf('@');
        let lastDotPos = this.state.email.lastIndexOf('.');
        if(!(lastAtPos < lastDotPos && lastAtPos > 0 && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)){
            formIsValid = false;
            errors.email = "Email tidak valid";  
            this.inputEmail.style = this.state.inputBorder;  
        }
    }

    //password
    if(!this.state.password){
      formIsValid = false;
      errors.password = "Password wajib diisi";
      this.inputPassword.style = this.state.inputBorder;
    }
    if(!this.state.re_password || this.state.re_password !== this.state.password){
      formIsValid = false;
      errors.re_password = "Password tidak sama";
      this.inputRePassword.style = this.state.inputBorder;  
    }

    //jenjang pendidikan
    if(!this.state.code_jenjang){
      formIsValid = false;
      errors.code_jenjang = "Jenjang pendidikan wajib diisi";
      this.inputJenPen.style = this.state.inputBorder;
    }

    if(this.state.code_jenjang === 'JP004'){
      //ket lainnya
      if(!this.state.ket_lainnya){
        formIsValid = false;
        errors.ket_lainnya = "Keterangan wajib diisi";
        this.inputKetLainnya.style = this.state.inputBorder;
      }
    }else{
      //kelas semester
      if(!this.state.code_kelas){
        formIsValid = false;
        errors.code_kelas = "Kelas / Semester wajib diisi";
        this.inputKelasSemester.style = this.state.inputBorder;
      }
      //kelas semester
      if(!this.state.sekolah_kampus){
        formIsValid = false;
        errors.sekolah_kampus = "Sekolah / kampus wajib diisi";
        this.inputSekolahKampus.style = this.state.inputBorder;
      }
    }
    
    this.setState({error:errors});
    return formIsValid;
    }
    

  getDataHarga(){
    const kodePromo = this.state.id_harga;
      const url = API_URL.url+'/harga/'+kodePromo;
      axios.get(url,{
          headers: {
              'Accept': 'application/json',
          }
      }).then(response => response.data)
      .then((data) => {
          this.setState({ dataHarga: data })
          // console.log('cekdata',this.state);
      });
  }

  registrationSubmit(e){
    e.preventDefault();
    if(this.handleValidation()){
      this.sendData();
    }else{
      this.setState({
          formAlert:{display:'unset'}, 
          formAlertClassName:'alert alert-warning',
          message:"Error ! Mohon cek kembali isian data"
      })
    }
  }

  sendData(){
    this.setState({
      message:"Proses simpan, mohon menunggu beberapa saat.",
      formAlert:{display:'block'},
      formAlertClassName:'alert alert-warning',
      btnDisabled: true
    });

    var formData = new FormData();
    formData.append('nama_lengkap',this.state.nama_lengkap);
    formData.append('no_wa',this.state.no_telp);
    formData.append('email',this.state.email);
    formData.append('password',this.state.password);
    formData.append('code_jenjang',this.state.code_jenjang);
    formData.append('code_kelas',this.state.code_kelas);
    formData.append('sekolah_kampus',this.state.sekolah_kampus);
    formData.append('ket_lainnya',this.state.ket_lainnya);
    axios({
      method: 'post',
      url:API_URL.url+'/member/registration',
      data:formData,
      config:{headers:{'Content-Type': 'multipart/form-data'}}
    }).then((reply)=>{
        //console.log(reply)
        if(reply.data === "ok"){
          // this.clearInput();
          this.hideAlert();
          this.setState({btnDisabled:false, showSwal:true})
        }else{
          this.setState({btnDisabled:false, formAlert:{display:'none'}})
          alert(reply.data);
          // this.setState(prevState => {
          //   let error = Object.assign({}, prevState.error);  
          //   error.email = reply.data;                                 
          // this.setState({error:error});  
          // return { error };                
          // })
          
          // this.setState({emailExist:reply.data}); 
          // this.inputEmail.style = this.state.inputBorder;
        }
    }).catch(function(reply){
        console.log(reply)
    });
  }

  hideAlert(){
    setTimeout(
        function() {
            this.setState({message: "",formAlert:{display:'none',transition:'0.5s ease'}});
        }
        .bind(this),
        5000
    );  
  }

  onBlurKodePromo(e){
    e.preventDefault()
    if(this.state.statusKode === "Y"){
      this.inputKodePromo.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_harga = '';                                 
            return { error };                   
        })
    }
  }

  onBlurMinatBakat(e){
    e.preventDefault()
    if(this.state.minat_bakat.length > 0){
      setTimeout(
        function() {
          this.inputMinatBakat.style = "";
          this.setState(prevState => {
              let error = Object.assign({}, prevState.error);  
              error.minat_bakat = '';                                 
              return { error };                   
          })
        }
        .bind(this),
        1000
      );
    }
  }

  onBlurNamaLengkap(e){
    e.preventDefault()
    if(this.state.nama_lengkap.length > 2){
        this.inputNamaLengkap.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.nama_lengkap = '';                                 
            return { error };                   
        })
    }
  }
  onBlurNoTelp(e){
      e.preventDefault()
      if(this.state.no_telp.length > 0){
          this.inputNoTelp.style = "";
          this.setState(prevState => {
              let error = Object.assign({}, prevState.error);  
              error.no_telp = '';                                 
              return { error };                   
          })
      }else{
        this.inputNoTelp.style = this.state.inputBorder;
        this.setState(prevState => {
            let errors = Object.assign({}, prevState.error);  
            errors.no_telp = 'No. Telp Tidak boleh kosong';                                 
            this.setState({error:errors});                  
        })
      }
  }
  onBlurEmail(e){
      e.preventDefault()
      if(this.state.email.length > 5){
          this.inputEmail.style = "";
          this.setState(prevState => {
              let error = Object.assign({}, prevState.error);  
              error.email = '';                                 
              return { error };                   
          })
      }
  }

  onBlurKonsultan(e){
    e.preventDefault()
    if(this.state.konsultan !== "0"){
        this.selectKonsultan.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.konsultan = '';                                 
            return { error };                   
        })
    }
  }

  onBlurPassword(e){
    e.preventDefault()
    if(this.state.password.length > 0){
        this.inputPassword.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.password = '';                                 
            return { error };                   
        })
    }
  }
  onBlurRePassword(e){
    e.preventDefault()
    if(this.state.re_password === this.state.password){
        this.inputRePassword.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.re_password = '';                                 
            return { error };                   
        })
    }else{
      this.inputRePassword.style = this.state.inputBorder;
      this.setState(prevState => {
          let errors = Object.assign({}, prevState.error);  
          errors.re_password = 'Password tidak sama!';                                 
          this.setState({error:errors});                  
      })
    }
  }

  onBlurPropinsi(e){
    e.preventDefault()
    if(this.state.id_propinsi.value != 0){
        this.inputPropinsi.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_propinsi = '';                                 
            return { error };                   
        })
    }
  }
  onBlurKabkota(e){
    e.preventDefault()
    if(this.state.id_kabkota.length > 2){
        this.inputKabKota.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_kabkota = '';                                 
            return { error };                   
        })
    }
  }

  onBlurJenpen(e){
    e.preventDefault()
    if(this.state.code_jenjang.length > 2){
        this.inputJenPen.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.code_jenjang = '';                                 
            return { error };                   
        })
    }
  }
  onBlurKelasSemester(e){
    e.preventDefault()
    if(this.state.code_kelas.length > 2){
        this.inputKelasSemester.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.code_kelas = '';                                 
            return { error };                   
        })
    }
  }
  onBlurSekolahKampus(e){
    e.preventDefault()
    if(this.state.sekolah_kampus.length > 2){
        this.inputSekolahKampus.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.sekolah_kampus = '';                                 
            return { error };                   
        })
    }
  }
  onBlurKetLainnya(e){
    e.preventDefault()
    if(this.state.ket_lainnya.length > 2){
        this.inputKetLainnya.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.ket_lainnya = '';                                 
            return { error };                   
        })
    }
  }

  getDataPropinsi(){
    const url = API_URL.master+'/propinsi';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.masterKey}`
        }
    }).then(response => response.data)
    .then((data) => {
        this.setState({ dataPropinsi: data })
        console.log('cek propinsi',this.state.dataPropinsi)
        // console.log('lalala',this.state.dataQuestion[0])
    })
  }

  getDataKabkota(){
    const url = API_URL.master+'/kabkota';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.masterKey}`
        }
    }).then(response => response.data)
    .then((data) => {
        this.setState({ dataKabkota: data })
        // console.log(this.state.dataKabkota)
    })
  }

  getDataMinatBakat(){
    const url = API_URL.url+'/minatbakat';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
        }
    }).then(response => response.data)
    .then((data) => {
        this.setState({ dataMinatBakat: data })
        console.log( 'cek minatbakat', this.state.dataMinatBakat)
    })
  }

  setPropinsi(e){
    e.preventDefault();
      this.setState({
          id_propinsi:e.target.value
      })

      const data = Object.values(this.state.dataKabkota);
      const id = e.target.value;
      const filterData = data.filter(dataFilter => {
        return dataFilter.id_propinsi == id;
        });
      this.setState({dataKabkotafilter:filterData})
      // console.log(filterData)
  }

  setKabkota(e){
    e.preventDefault();
      this.setState({
          id_kabkota:e.target.value
      })
      
      const prop = this.state.id_propinsi;
      const kab = e.target.value;
      const url = API_URL.master+'/konsultan/area/'+prop+'/'+kab;
      axios.get(url,{
          headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${API_URL.masterKey}`
          }
      }).then(response => response.data)
      .then((data) => {
          this.setState({ id_area: data[0].id_area })
          const dataKonsultan = Object.values(this.state.dataKonsultan);
          const id = data[0].id_area;
          const filterData = dataKonsultan.filter(dataFilter => {
            return dataFilter.id_area == id;
            });
            // console.log('cek id area', filterData)
          this.setState({dataKonsultanFilter:filterData})
      })
  }

  componentDidMount() {
    //this.checkIssetPayment();
    this.getDataKonsultan();
    //this.getDataHarga();
    this.getDataPropinsi();
    this.getDataKabkota();
    this.getDataMinatBakat();
    this.getDataJenpen();
    this.getDataKelasSemester();
  }

  // checkIssetPayment(){
  //   let lValid = true;
  //   const kode_member = this.state.kode_member;
  //   const url = API_URL.url+'/member/find_by_kode/'+kode_member;
  //   axios.get(url,{
  //       headers: {
  //           'Accept': 'application/json',
  //       }
  //   }).then(response => response.data)
  //   .then((data) => {
  //       //this.setState({ dataLocations: data })
  //       console.log(data[0]);
  //       if(data[0].status_payment === "Y" && data[0].bukti_payment !== ""){
  //         return;
  //       }else{
  //         window.location.href = "/infopayment";          
  //       }
  //   });
  // }


  getDataKonsultan(){
    const url = API_URL.master+'/konsultan';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.masterKey}`
        }
    }).then(response => response.data)
    .then((data) => {
        this.setState({ dataKonsultan: data })
        // console.log('cek list konsultan',this.state.dataKonsultan)
    })
  }
  
  radioChangeHandler = (event) =>{
    this.setState({
        konsultan:event.target.value
    })
  }

  getDataJenpen(){
    const url = API_URL.master+'/jenpen';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.masterKey}`
        }
    }).then(response => response.data)
    .then((data) => {
        this.setState({ dataJenPen: data })
    })
  }

  getDataKelasSemester(){
    const url = API_URL.master+'/kelas_semester';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.masterKey}`
        }
    }).then(response => response.data)
    .then((data) => {
        this.setState({ dataKelasSemester: data })
    })
  }

  setJenpen(e){
    e.preventDefault();
      this.setState({
          code_jenjang:e.target.value
      })

      if(e.target.value === 'JP004') {
        this.setState({
          renderInputKet:"",
          renderInputSekolah:"none"
        })
      }else{
        const data = Object.values(this.state.dataKelasSemester);
        const id = e.target.value;
        const filterData = data.filter(dataFilter => {
          return dataFilter.code_jenjang == id;
          });
        this.setState({
          dataKelasSemesterFilter:filterData, 
          renderInputSekolah:"",
          renderInputKet:"none"
        })
        // console.log('filter kelas',filterData)
      }
  }

  setKelasSemester(e){
    e.preventDefault();
      this.setState({
          code_kelas:e.target.value
      })
      // console.log('kelas',this.state.code_kelas)
  }


  clearInput() {
    this.inputNamaLengkap.value = "";
    this.inputNoTelp.value = "";
    this.inputEmail.value = "";
    this.inputNamaSekolahlah.value = "";
    this.inputKelasSemester.value = "";
    this.inputPassword.value = "";
    this.selectKonsultan.value = "";
    this.inputRePassword.value = "";
    this.inputKodePromo.value = "";
  }

  renderInputSekolah() {
    return(
      <div style={{display:this.state.renderInputSekolah}}>
        <FormGroup style={{marginBottom:"0px"}}>
          <Label for="code_kelas" style={{ color: 'white', fontWeight:'500'}}>Kelas / Semester</Label>
          <select md="3" className="form-control" type="code_kelas"  id="code_kelas" placeholder=""
            // onChange={(e) => this.setState({id_kabkota:e.target.value})}
            onChange={(e) => this.setKelasSemester(e)}
            onBlur={(e) => this.onBlurKelasSemester(e)}
            ref={e => this.inputKelasSemester = e}
          >
            <option value="0">--Pilih kelas / semester--</option>
            {this.state.dataKelasSemesterFilter.map((data4)=>(
              <option key={data4.code_kelas} value={data4.code_kelas}>{data4.kelas}</option>
            ))}
          </select>
          <label className="error-label-register">
              {this.state.error.code_kelas}
          </label>
        </FormGroup>

        <FormGroup style={{marginBottom:"0px"}}>
          <Label for="sekolah_kampus" style={{ color: 'white', fontWeight:'500'}}>Sekolah / Kampus</Label>
          <input md="3" className="form-control" type="sekolah_kampus"  id="sekolah_kampus" placeholder=""
            onChange={(e) => this.setState({sekolah_kampus:e.target.value})}
            onBlur={(e) => this.onBlurSekolahKampus(e)}
            ref={e => this.inputSekolahKampus = e}
          />
          <label className="error-label-register">
              {this.state.error.sekolah_kampus}
          </label>
        </FormGroup>
      </div>
    );
  }

  renderInputKet() {
    return(
      <div style={{display:this.state.renderInputKet}}>
      <FormGroup style={{marginBottom:"0px"}}>
        <Label for="ket_lainnya" style={{ color: 'white', fontWeight:'500'}}>Keterangan</Label>
        <Input md="3" className="form-control" type="textarea"  id="ket_lainnya" placeholder=""
          onChange={(e) => this.setState({ket_lainnya:e.target.value})}
          onBlur={(e) => this.onBlurKetLainnya(e)}
          ref={e => this.inputKetLainnya = e}
        />
        <label className="error-label-register">
            {this.state.error.ket_lainnya}
        </label>
      </FormGroup>
    </div>
    );
  }

  render() {

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };

    const responsiveBanner = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };

  return (
    <>
      <ExamplesNavbar />
      {/* <LandingPageHeader /> */}
      <div className="main">
        <div className="section page-header section-dark section-nucleo-icons" style={{ backgroundColor: 'black' }}>
          <Container>
            <Row>
              <Col className="content-center" lg="6" md="12" style={{ backgroundColor: '' }}>
                <h2 className="title1" style={{ fontWeight: 'bold' }}>Selamat Datang di NIEC Prioritas Member</h2>
                <br />
                <p className="description" style={{ fontWeight: '500' }}>
                  Website ini ditujukan untuk registrasi user sebelum melakukan demo E Tallent Mapping.
                </p>
                <br />
              </Col>
              <Col lg="6" md="12" style={{ backgroundColor: '' }}>
                {/* <div className="icons-container"> */}
                  <div className="motto text-center">
                    <img
                      alt="logo-membership"
                      className={classnames("membership-card")}
                      src={require('./../../../assets/img/niec-logo/Logo_Member-01.png')}
                    />
                  </div>
                {/* </div> */}
              </Col>
            </Row>
          </Container>
        </div>{" "}

        <div className="section" id="formRegis" style={{ backgroundColor: 'black'}}>
          <Container>
          <h2 className="title2 text-center">Registrasi Sekarang</h2>
          <form>
            <Row>
              <Col className="ml-auto mr-auto" lg="6" md="12">
                <FormGroup>
                  <Label for="nama_lengkap" style={{ color: 'white', fontWeight:'500'}}>Nama Lengkap</Label>
                  <input className="form-control" type="nama_lengkap"  id="nama_lengkap" placeholder="Nama Lengkap"
                    onChange={(e) => this.setState({nama_lengkap:e.target.value})}
                    onBlur={(e) => this.onBlurNamaLengkap(e)}
                    ref={e => this.inputNamaLengkap = e}
                  />
                  <label className="error-label-register">
                      {this.state.error.nama_lengkap}
                  </label>
                </FormGroup>
                <FormGroup>
                  <Label for="inputEmail4" style={{ color: 'white', fontWeight:'500'}}>Email</Label>
                  <input className="form-control" type="email"  id="inputEmail4" placeholder="Email"
                    onChange={(e) => this.setState({email:e.target.value})}
                    onBlur={(e) => this.onBlurEmail(e)}
                    ref={e => this.inputEmail = e}
                  />
                  <label className="error-label-register">
                      {this.state.error.email}
                  </label>
                </FormGroup>
                <FormGroup>
                  <Label for="no_wa" style={{ color: 'white', fontWeight:'500'}}>No HP/WA</Label>
                  <input className="form-control" type="number"  id="no_wa" placeholder="No tlp / WhatsApp"
                    onChange={(e) => this.setState({no_telp:e.target.value})}
                    onBlur={(e) => this.onBlurNoTelp(e)}
                    ref={e => this.inputNoTelp = e}
                  />
                  <label className="error-label-register">
                      {this.state.error.no_telp}
                  </label>
                </FormGroup>
                <FormGroup style={{marginBottom:"0px"}}>
                    <Label for="code_jenjang" style={{ color: 'white', fontWeight:'500'}}>Jenjang Pendidikan</Label>
                    <select md="3" className="form-control" type="code_jenjang"  id="code_jenjang" placeholder=""
                      // onChange={(e) => this.setState({id_kabkota:e.target.value})}
                      onChange={(e) => this.setJenpen(e)}
                      onBlur={(e) => this.onBlurJenpen(e)}
                      ref={e => this.inputJenPen = e}
                    >
                      <option value="0">--Jenjang pendidikan--</option>
                      {this.state.dataJenPen.map((data3)=>(
                        <option key={data3.code_jenjang} value={data3.code_jenjang}>{data3.jenjang_pendidikan}</option>
                      ))}
                    </select>
                    <label className="error-label-register">
                        {this.state.error.code_jenjang}
                    </label>
                  </FormGroup>

                  {this.renderInputSekolah()}
                  {this.renderInputKet()}
                
                <FormGroup>
                  <Label for="inputPassword4" style={{ color: 'white', fontWeight:'500'}}>Password</Label>
                  <input className="form-control" type="password"  id="inputPassword4" placeholder="Password" autoComplete="off"
                    onChange={(e) => this.setState({password:e.target.value})}
                    onBlur={(e) => this.onBlurPassword(e)}
                    ref={e => this.inputPassword = e}
                  />
                  <label className="error-label-register">
                      {this.state.error.password}
                  </label>
                </FormGroup>
                <FormGroup>
                  <Label for="inputRePassword4" style={{ color: 'white', fontWeight:'500'}}>Repeat Password</Label>
                  <input className="form-control" type="password"  id="inputRePassword4" placeholder="Password" autoComplete="off"
                    onChange={(e) => this.setState({re_password:e.target.value})}
                    onBlur={(e) => this.onBlurRePassword(e)}
                    ref={e => this.inputRePassword = e}
                  />
                  <label className="error-label-register">
                      {this.state.error.re_password}
                  </label>
                </FormGroup>
              </Col>

              <SweetAlert
                show={this.state.showSwal}
                title="Selamat"
                text="Anda Berhasil Mendaftar"
                icon= 'success'
                confirmButtonText="Login"
                onConfirm={() => 
                  window.location.href="/login"
                }
              />
            </Row>
            <Row style={{marginTop:"30px"}}>
                  <Col>
                    <Row style={{marginBottom:"20px"}}>
                      <Col>
                        <div id="message" className={this.state.formAlertClassName+" alert-dismissible"} style={this.state.formAlert}>
                          {this.state.message}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <Button disabled={this.state.btnDisabled} className="text-center" type="submit"  onClick={e=>this.registrationSubmit(e)} style={{ backgroundColor:'#C39B3F' }}>Kirim</Button>
                        </div>
                      </Col>
                    </Row>  
                  </Col>
                </Row>
          </form>

          </Container>
        </div>
      </div>
      <DemoFooter />
    </>
  );
            }
}

export default LandingPage;
