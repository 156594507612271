import React, { Component } from "react";
import Cookies from 'universal-cookie';
import axios from 'axios';
import { connect } from 'react-redux';
import ScrollArea from "react-scrollbar";

// reactstrap components
import {
  Row,
  Col
} from "reactstrap";

class ListBooking extends Component {
    render() {
        return (
            <ScrollArea
                speed={0.8}
                className="area"
                contentClassName="content"
                horizontal={false}
                style={{ height:"200px" }}
            >
            {
                this.props.bookingList.map(bookingList => {
                    return(
                    <li key={bookingList.id_booking}>
                        <Row>
                            <Col className="ml-auto mr-auto" lg="2" md="4" xs="4">
                                <h6>{bookingList.tanggal}<br />
                                    <small>{bookingList.jam}</small>
                                    <br />
                                    <small>{bookingList.keterangan}</small>
                                </h6>
                            </Col>
                            <Col className="ml-auto mr-auto" lg="3" md="4" xs="4">
                                <h6><br />
                                    <small>{bookingList.nama_kota}</small>
                                    <br />
                                    <small></small>
                                </h6>
                            </Col>
                        </Row>
                    </li>
                    );
                })
            }
            </ScrollArea>
        );
    }
}

const reduxState = (state) => ({
    bookingList: state.listBooking,
  })
  
export default connect(reduxState)(ListBooking);