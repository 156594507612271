/** @format */
import _Footers from './Footers';
import _LandingPageHeader from './Header/LandingPageHeader';
import _HomeHeader from './Header/HomeHeader';
import _Register from './Register';
import _RegisterPageContent from './Content/RegisterPageContent';
import _TransferPageContent from './Content/TransferPageContent';
import _ExamplesNavbar from './Navbars/ExamplesNavbar';
import _StaticNavbar from './Navbars/StaticNavBar';
import _CardInfo from './CardInfo';
import _ComingSoonContent from './Content/ComingSoonContent';
import _ListBooking from './Content/ListBooking';
import _MemberNavbar from './Navbars/MemberNavbar';
import _PaymentNavbar from './Navbars/PaymentNavbar';
import _ProfileHeader from './Header/ProfileHeader';
import _ProfileNavbar from './Navbars/ProfileNavbar';
import _VCard from './VCard';
import _Vexpo from './Event/Vexpo';
import _Openhouse from './Event/Openhouse';
import _InfoSession from './Event/InfoSession';
import _SplashScreen from './Content/SplashScreen';
import _IdleTimeOutModal from './IdleTimeOutModal';
import _KonsultasiZoom from './KonsultasiZoom';
import _AppreciationNight from './Event/AppreciationNight';

export const LandingPageHeader = _LandingPageHeader;
export const RegisterPageContent = _RegisterPageContent;
export const TransferPageContent = _TransferPageContent;
export const DemoFooter = _Footers;
export const ExamplesNavbar = _ExamplesNavbar;
export const PaymentNavbar = _PaymentNavbar;
export const StaticNavbar = _StaticNavbar;
export const CardInfo = _CardInfo;
export const ComingSoonContent = _ComingSoonContent;
export const ListBooking = _ListBooking;
export const MemberNavbar = _MemberNavbar;
export const HomeHeader = _HomeHeader;
export const ProfileHeader = _ProfileHeader;
export const Register = _Register;
export const ProfileNavbar = _ProfileNavbar;
export const VCard = _VCard;
export const Vexpo = _Vexpo;
export const Openhouse = _Openhouse;
export const InfoSession = _InfoSession;
export const SplashScreen  = _SplashScreen;
export const IdleTimeOutModal  = _IdleTimeOutModal;
export const KonsultasiZoom  = _KonsultasiZoom;
export const AppreciationNight  = _AppreciationNight;
