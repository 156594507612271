import React, { Component, Fragment } from 'react';
//import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import IdleTimer from 'react-idle-timer'

// Styles
import './assets/css/bootstrap.min.css';
import './assets/scss/paper-kit.scss';
import './assets/demo/demo.css';

import { LandingPage, Home, Profile, Login, InfoPayment,Logout, ChPassword } from "./containers/pages"
import { Register, IdleTimeOutModal  } from "./components/molecules"

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout:1000 * 60 * 15,
      currentUser:'',
      statusPayment:'',
      // timeout: 5000,
      // isTimedOut: false,
      isTimedOut: false,
      showModal: false
    }
      this.idleTimer = null;
      this.onAction = this._onAction.bind(this);
      this.onActive = this._onActive.bind(this);
      this.onIdle = this._onIdle.bind(this);
      this.handleClose = this.handleClose.bind(this)
      this.handleLogout = this.handleLogout.bind(this)


    }

  _onAction(e) {
    console.log("user did something", e);
    console.log("time remaining", this.idleTimer.getRemainingTime());
  }

  _onActive(e) {
    console.log("user is active", e);
    console.log("time remaining", this.idleTimer.getRemainingTime());
  }

  // _onIdle(e) {
  //   console.log("user is idle", e);
  //   console.log("last active", this.idleTimer.getLastActiveTime());
  //   console.log("cek", this.state.isTimedOut);
  //   this.setState({isTimedOut: true});
  //   sessionStorage.setItem('status_login', false);
  //   sessionStorage.setItem('status_payment', false);

  //   const session = sessionStorage.getItem('status_login');
  //     if( session ){
  //       window.location.href="/logout";
  //     }
  // }

  _onIdle(e) {
    console.log('user is idle', e)
    console.log("last active", this.idleTimer.getLastActiveTime());
      // this.props.history.push('/')
      this.setState({showModal: true})
      this.idleTimer.reset();
    
  }

  handleClose() {
    this.idleTimer.reset();
    this.setState({showModal: false})
  }

  handleLogout() {
    this.setState({showModal: false})
    // this.props.history.push('/')
    window.location.href="/logout";
  }

  render() {
    const checkUser = sessionStorage.getItem("status_login");
    const checkPayment = sessionStorage.getItem("status_payment");
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/logout" component={Logout} />
          <Route path="/chpassword/:tokenfgp" exact component={ChPassword}/>  
          
          {checkUser ?
            <Switch>
              <Route path="/infopayment" exact component={InfoPayment} />
              {checkPayment ?
                <Fragment>
                  <IdleTimer
                      ref={ref => {
                        this.idleTimer = ref;
                      }}
                      // element={document}
                      onActive={this.onActive}
                      onIdle={this.onIdle}
                      onAction={this.onAction}
                      debounce={250}
                      // timeout={1000 * 60 * 15}
                      // timeout={5 * 1000}
                      timeout={this.state.timeout}
                  />
                  <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                  />
                  <Route path="/home" exact component={Home} />
                  <Route path="/profile" component={Profile} />
                  {/* <Route path="/chpassword" component={ChPassword} /> */}
                </Fragment>
              :
                <Redirect to={"/infopayment"} />
              }
            </Switch>
            :
            <Redirect to={"/login"} />
            }
        </Switch>   
      </Router>
    );
      }
}

export default App;
