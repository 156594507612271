import React , { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
    Input,
    Row,
    Col,
    FormGroup,
    Label,
  } from "reactstrap";
//   import ReactPixel from 'react-facebook-pixel';
//import SweetAlert from 'sweetalert2-react';
import API_URL from  '../../../ApiUrl';
import SweetAlert from 'sweetalert2-react';

class AppreciationNight extends Component {
  constructor(props) {
    super(props);
    this.state = {
        id_member: this.props.dataUser[0].id_member,
        kode_member: this.props.dataUser[0].kode_member,
        nama: this.props.dataUser[0].nama_lengkap,
        notelp: this.props.dataUser[0].no_wa,
        email: this.props.dataUser[0].email,

        code_kategori:"",
        nama_ayah:"",
        nama_ibu:"",
        sekolah_partner:"",
        university_rep:"",
        dataKategori: [],
        renderInputOrangTua:"none",
        renderInputSekolah:"none",
        renderInputUnivRep:"none",
        renderInputOnshore:"none",
        wakilkan:"",

        checkemail:"",
        pesanEmailError:"",

        message:"Error !",
        error: {},
        formAlert:{display:'none'},
        formAlertClassName:'alert alert-warning',
        inputBorder: "border:1px solid #ca0b0b;",

        idParent : "",
        showSwal: false,
        hide:"",

        btnDisabled: false
    };
  }

    componentDidMount() {
        //ReactPixel.init('552257272349272');
        // ReactPixel.pageView(); // For tracking page view
        // ReactPixel.track(event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
        // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
        // ReactPixel.trackCustom(event, data); // For tracking custom events
        // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events
        this.getKategori();
        this.checkEmail();  
    }

    getKategori() {
        const url = API_URL.appreciationnight+'/kategori';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.appnightKey}`
            }
        }).then(response => response.data)
        .then((data) => {
            this.setState({ dataKategori: data })
            console.log('tes kategori',data)
        })
    }

    setKategori(e){
        e.preventDefault();
        this.setState({
            code_kategori:e.target.value
        })

        if(e.target.value === 'CK001') {
            this.setState({
            renderInputOrangTua:"",
            renderInputOnshore:"none",
            renderInputSekolah:"none",
            renderInputUnivRep:"none"
            })
        }else if(e.target.value === 'CK002'){
            this.setState({
            renderInputOrangTua:"none",
            renderInputOnshore:"",
            renderInputSekolah:"none",
            renderInputUnivRep:"none"
            })
        }else if(e.target.value === 'CK003'){
            this.setState({
            renderInputOrangTua:"none",
            renderInputOnshore:"none",
            renderInputSekolah:"",
            renderInputUnivRep:"none"
            })
        }else if(e.target.value === 'CK004'){
            this.setState({
            renderInputOrangTua:"none",
            renderInputOnshore:"none",
            renderInputSekolah:"none",
            renderInputUnivRep:""
            })
        }
        // console.log(this.state.code_kategori)
    }

    setWakilkan(e){
        e.preventDefault();
        this.setState({
        wakilkan:e.target.value,
        })
        if(e.target.value === "1") {
            this.setState({
            renderInputOrangTua:"",
            })
        }else{
            this.setState({
            renderInputOrangTua:"none",
            })
        }
        console.log(e.target.value)
    }

    handleValidation(){
        let errors = {};
        let formIsValid = true;
        
        //kategori
        if(!this.state.code_kategori){
          formIsValid = false;
          errors.code_kategori = "Kategori wajib diisi";
          this.inputKategori.style = this.state.inputBorder;
        }else{
          if(this.state.code_kategori === 'CK001'){
            //nama ayah
            if(!this.state.nama_ayah || !this.state.nama_ayah){
              formIsValid = false;
              errors.nama_ayah = "Isi salah satu dari nama Orang Tua";
              this.inputNamaAyah.style = this.state.inputBorder;
              errors.nama_ibu = "Isi salah satu dari nama Orang Tua";
              this.inputNamaIbu.style = this.state.inputBorder;
            }
          }
    
          if(this.state.code_kategori === 'CK002'){
            //wakilkan
            if(!this.state.wakilkan){
              formIsValid = false;
              errors.wakilkan = "wajib diisi";
              this.inputWakilkan.style = this.state.inputBorder;
            }
            if(this.state.wakilkan === '1'){
                //nama ayah
                if(!this.state.nama_ayah || !this.state.nama_ayah){
                  formIsValid = false;
                  errors.nama_ayah = "Isi salah satu dari nama Orang Tua";
                  this.inputNamaAyah.style = this.state.inputBorder;
                  errors.nama_ibu = "Isi salah satu dari nama Orang Tua";
                  this.inputNamaIbu.style = this.state.inputBorder;
                }
            }
          }
    
          if(this.state.code_kategori === 'CK003'){
            //sekolah
            if(!this.state.sekolah_partner){
              formIsValid = false;
              errors.sekolah_partner = "wajib diisi";
              this.inputSekolahPartner.style = this.state.inputBorder;
            }
          }
    
          if(this.state.code_kategori === 'CK004'){
            //univ
            if(!this.state.university_rep){
              formIsValid = false;
              errors.university_rep = "wajib diisi";
              this.inputUniversityRep.style = this.state.inputBorder;
            }
          }
        }
        
        this.setState({error:errors});
        return formIsValid;    
    }

    registrationSubmit(e){
        e.preventDefault();
            if(this.handleValidation()){
                 this.sendData();
            }else{
                // this.setState({
                //     formAlert:{display:'unset'}, 
                //     formAlertClassName:'alert alert-warning',
                //     message:"",
                //     hide: "none"
                // })
            }
    }

    
    hideAlert(){
        setTimeout(
            function() {
                this.setState({message: "",formAlert:{display:'none',transition:'0.5s ease'}});
            }
            .bind(this),
            5000
        );  
    }

    checkEmail(){
      const url = API_URL.appreciationnight+'/peserta/cekmail';
      var formData = new FormData();
      formData.append('email',this.state.email);
      axios({
          method: 'post',
          url: url,
          data:formData,
          headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${API_URL.appnightKey}`
          }
      }).then(response => response.data)
      .then((data) => {
          this.setState({ checkemail: data[0].status, pesanEmailError: data[0].pesan })
          // console.log('cek data email', this.state.checkemail)
          // console.log('cek pesan email', this.state.pesanEmailError)
          console.log('cek email1', this.state.pesanEmailError)
      })
    }

    sendData(){
      this.setState({
          message:"Proses simpan, mohon menunggu beberapa saat.",
          formAlert:{display:'block'},
          formAlertClassName:'alert alert-warning',
          btnDisabled: true
      });

        const url = API_URL.appreciationnight+'/peserta/registermember';
        
        var formData = new FormData();
        formData.append('kode_member',this.state.kode_member);
        formData.append('nama_lengkap',this.state.nama);
        formData.append('no_wa',this.state.notelp);
        formData.append('email',this.state.email);

        formData.append('code_kategori',this.state.code_kategori);
        formData.append('nama_ayah',this.state.nama_ayah);
        formData.append('nama_ibu',this.state.nama_ibu);
        formData.append('sekolah_partner',this.state.sekolah_partner);
        formData.append('university_rep',this.state.university_rep);

        axios({
            method: 'post',
            url: url,
            data:formData,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.appnightKey}`
            }
        }).then((response)=>{
            console.log('testing',response.data);
            // if(response.data[0].id_register !== 0){
            if(response.data === "ok"){
                this.hideAlert();
                this.setState({btnDisabled:false, showSwal:true})
            }else{
                this.setState({
                    // message:"Terjadi error di server! (102)",
                    message:response.data,
                    formAlert:{display:'block'},
                    formAlertClassName:'alert alert-danger',
                    btnDisabled:false,
                });
            }
        }).catch(function(response){
            console.log(response);
            this.setState({
                message:"Terjadi error di server! (101)",
                formAlert:{display:'block'},
                formAlertClassName:'alert alert-danger'
            });
        })
    }
    
      onBlurKategori(e){
        e.preventDefault()
        if(this.state.code_kategori.length > 2){
            this.inputKategori.style = "";
            this.setState(prevState => {
                let error = Object.assign({}, prevState.error);  
                error.code_kategori = '';                                 
                return { error };                   
            })
        }
      }
    
      onBlurOrtu(e){
        e.preventDefault()
        if(this.state.nama_ayah.length > 2 || this.state.nama_ibu.length > 2){
            this.inputNamaAyah.style = "";
            this.inputNamaIbu.style = "";
            this.setState(prevState => {
                let error = Object.assign({}, prevState.error);  
                error.nama_ayah = '';                                 
                error.nama_ibu = '';                                 
                return { error };                   
            })
        }
      }

      onBlurSekolahPartner(e){
        e.preventDefault()
        if(this.state.sekolah_partner.length > 2){
            this.inputSekolahPartner.style = "";
            this.setState(prevState => {
                let error = Object.assign({}, prevState.error);  
                error.sekolah_partner = '';                                 
                return { error };                   
            })
        }
      }
      
      onBlurUniversityRep(e){
        e.preventDefault()
        if(this.state.university_rep.length > 2){
            this.inputUniversityRep.style = "";
            this.setState(prevState => {
                let error = Object.assign({}, prevState.error);  
                error.university_rep = '';                                 
                return { error };                   
            })
        }
      }
      
      onBlurWakilkan(e){
        e.preventDefault()
        if(this.state.wakilkan.length > 0){
            this.inputWakilkan.style = "";
            this.setState(prevState => {
                let error = Object.assign({}, prevState.error);  
                error.wakilkan = '';                                 
                return { error };                   
            })
        }
      }

      renderInputOrangTua() {
        return(
          <div style={{display:this.state.renderInputOrangTua}}>
            <Label for="code_kategori" style={{ color: 'white', fontWeight:'500', fontStyle:"italic"}}>Data Orang Tua dapat isi salah satu atau seleruhnya.</Label>
            <FormGroup style={{marginBottom:"0px"}}>
              <Label for="nama_ayah" style={{ color: '#7A7A7A', fontWeight:'500'}}>Nama Ayah</Label>
              <input md="3" className="form-control" type="nama_ayah"  id="nama_ayah" placeholder=""
                onChange={(e) => this.setState({nama_ayah:e.target.value})}
                onBlur={(e) => this.onBlurOrtu(e)}
                ref={e => this.inputNamaAyah = e}
              />
              <label className="error-label-register">
                  {this.state.error.nama_ayah}
              </label>
            </FormGroup>
    
            <FormGroup style={{marginBottom:"0px"}}>
              <Label for="nama_ibu" style={{ color: '#7A7A7A', fontWeight:'500'}}>Nama Ibu</Label>
              <input md="3" className="form-control" type="nama_ibu"  id="nama_ibu" placeholder=""
                onChange={(e) => this.setState({nama_ibu:e.target.value})}
                onBlur={(e) => this.onBlurOrtu(e)}
                ref={e => this.inputNamaIbu = e}
              />
              <label className="error-label-register">
                  {this.state.error.nama_ibu}
              </label>
            </FormGroup>
          </div>
        );
      }
    
      renderInputOnshore() {
        return(
          <div style={{display:this.state.renderInputOnshore}}>
            <FormGroup style={{marginBottom:"0px"}}>
              <Label for="wakilkan" style={{ color: 'white', fontWeight:'500'}}>Tidak bisa hadir dan ingin di wakilkan dengan Orang Tua?</Label>
              <select md="3" className="form-control" type="wakilkan"  id="wakilkan" placeholder=""
                // onChange={(e) => this.setState({id_kabkota:e.target.value})}
                onChange={(e) => this.setWakilkan(e)}
                onBlur={(e) => this.onBlurWakilkan(e)}
                ref={e => this.inputWakilkan = e}
              >
                <option value="0">--</option>
                <option key="1" value="1">Ya</option>
                <option key="2" value="2">Tidak</option>
              </select>
                           
              <label className="error-label-register">
                  {this.state.error.wakilkan}
              </label>
            </FormGroup>
          </div>
        );
      }
    
      renderInputSekolah() {
        return(
          <div style={{display:this.state.renderInputSekolah}}>
            <FormGroup style={{marginBottom:"0px"}}>
              <Label for="sekolah_partner" style={{ color: '#7A7A7A', fontWeight:'500'}}>Nama Sekolah</Label>
              <input md="3" className="form-control" type="sekolah_partner"  id="sekolah_partner" placeholder=""
                onChange={(e) => this.setState({sekolah_partner:e.target.value})}
                onBlur={(e) => this.onBlurSekolahPartner(e)}
                ref={e => this.inputSekolahPartner = e}
              />
              <label className="error-label-register">
                  {this.state.error.sekolah_partner}
              </label>
            </FormGroup>
          </div>
        );
      }
    
      renderInputUnivRep() {
        return(
          <div style={{display:this.state.renderInputUnivRep}}>
            <FormGroup style={{marginBottom:"0px"}}>
              <Label for="university_rep" style={{ color: '#7A7A7A', fontWeight:'500'}}>University</Label>
              <input md="3" className="form-control" type="university_rep"  id="university_rep" placeholder=""
                onChange={(e) => this.setState({university_rep:e.target.value})}
                onBlur={(e) => this.onBlurUniversityRep(e)}
                ref={e => this.inputUniversityRep = e}
              />
              <label className="error-label-register">
                  {this.state.error.university_rep}
              </label>
            </FormGroup>
          </div>
        );
      }

    render() {
        if(this.state.checkemail === "Tolak"){
            return (
                <section className="section" id="register" style={{backgroundColor:"transparent"}}>
                
                <div className="container">
                    <div className="row">
                    {/* <EdufestSchedule /> */}
                        <div className="col-lg-8 offset-lg-2">
                            <h1 className="section-title text-center">REGISTER HERE</h1>
                            <div className="section-title-border margin-t-20"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="custom-form mt-4 pt-4">
                                <form method="post" action="" name="contact-form" id="contact-form">
                                    <div className="row">
                                        <div className="col-lg">
                                        <Label style={{ color: 'white', fontWeight:'500'}}>Anda telah melakukan pendaftaran sebelumnya.</Label><br /><br />
                                        </div>
                                    </div>

        
                                    <div className="row" style={{marginBottom:"30px", marginTop:"30"}}>
                                        <div className="col-lg-12">
                                            <div id="message" className={this.state.formAlertClassName+" alert-dismissible"} style={this.state.formAlert}>
                                                {this.state.message}
                                            </div>
                                        </div>
                                    </div>

                                    <a href="/home" style={{color:'#00aeef', textDecoration:"underlined", display:this.state.hide}}>Kembali</a>
                                </form>
                            </div>  
                        </div>
                    </div>
                </div>
                
            </section> 
            );
        }else{
            return (
                <section className="section" id="register" style={{backgroundColor:"transparent"}}>
                <div className="container">
                    <div className="row">
                    {/* <EdufestSchedule /> */}
                        <div className="col-lg-8 offset-lg-2">
                            <h1 className="section-title text-center">REGISTER HERE</h1>
                            <div className="section-title-border margin-t-20"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="custom-form mt-4 pt-4">
                                <form>
                                    <div className="row">
                                        <div className="col-lg">
                                            <FormGroup style={{marginBottom:"0px"}}>
                                                <Label for="code_kategori" style={{ color: 'white', fontWeight:'500'}}>Kategori</Label>
                                                <select md="3" className="form-control" type="code_kategori"  id="code_kategori" placeholder=""
                                                // onChange={(e) => this.setState({id_kabkota:e.target.value})}
                                                onChange={(e) => this.setKategori(e)}
                                                onBlur={(e) => this.onBlurKategori(e)}
                                                ref={e => this.inputKategori = e}
                                                >
                                                <option value="0">--Pilih Kategori--</option>
                                                {this.state.dataKategori.map((data2)=>(
                                                    <option key={data2.code_kategori} value={data2.code_kategori}>{data2.kategori}</option>
                                                ))}
                                                </select>
                                            
                                                <label className="error-label-register">
                                                    {this.state.error.code_kategori}
                                                </label>
                                            </FormGroup>

                                            {this.renderInputOnshore()}
                                            {this.renderInputOrangTua()}
                                            {this.renderInputSekolah()}
                                            {this.renderInputUnivRep()}
                                        </div>
                                    </div>

        
                                    <div className="row" style={{marginTop:"50px",}}>
                                        <div className="col-lg-12">
                                            <div id="message" className={this.state.formAlertClassName+" alert-dismissible"} style={this.state.formAlert}>
                                                {this.state.message}
                                            </div>
                                        </div>
                                    </div>

                                    <a href="/home" style={{color:'#00aeef', textDecoration:"underlined", display:this.state.hide}}>Kembali</a>
                                    <div className="row">
                                        <div className="col-lg-12 text-right">
                                            <input disabled={this.state.btnDisabled} type="submit" id="submit" name="register" onClick={e=>this.registrationSubmit(e)} className="submitBnt btn btn-custom" value="Submit" />
                                            <div id="simple-msg"></div>
                                        </div>
                                    </div>

                                    <SweetAlert
                                        show={this.state.showSwal}
                                        title="Selamat"
                                        text="Anda Berhasil Mendaftar"
                                        icon= 'success'
                                        confirmButtonText="Kembali"
                                        onConfirm={() => 
                                        window.location.href="/home"
                                        }
                                    />
                                </form>
                            </div>  
                        </div>
                    </div>
                </div>
            </section>
            );
        }
    }
}
// export default Register;

const reduxState = (state) => ({
  dataUser: state.detailUser.user
})
 
 export default connect(reduxState)(AppreciationNight);
