const initialState = {
    detailUser: {},
    KonsultanMember: {},
    linkHR:{},
    listBooking: {}
}

const reducer = (state=initialState , action) => {
    if(action.type === 'GET_DETAIL_USER'){
        return {
            ...state,
            detailUser: action.value
        }
    }
    if(action.type === 'GET_KONSULTAN_MEMBER'){
        return {
            ...state,
            KonsultanMember: action.value
        }
    }
    if(action.type === 'GET_LIST_BOOKING'){
        return {
            ...state,
            listBooking: action.value
        }
    }
    if(action.type === 'GET_CALENDLY_LINK'){
        return {
            ...state,
            linkHR: action.value
        }
    }
    if(action.type === 'LOGOUT'){
        return {
            ...state,
            initialState,
            KonsultanMember: {},
            listBooking: {},
            detailUser: {},
        }
    }
    return state;
}

export default reducer;