/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import {ListBooking} from './../../../components/molecules/';

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

import { ProfileNavbar, DemoFooter, ProfileHeader, VCard } from './../../../components/molecules/';

function ProfilePage() {
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ProfileNavbar />
      <ProfileHeader />
      {/* <VCard /> */}
      <div className="section profile-content">
        <Container>
          <br />
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <Nav role="tablist" tabs>
                <NavItem>
                  {/* <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    tag={Link}
                    onClick={() => {
                      toggle("1");
                    }}
                  > */}
                    Booking Test
                  {/* </NavLink> */}
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    tag={Link}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Notifikasi
                  </NavLink>
                </NavItem> */}
              </Nav>
            </div>
          </div>
          {/* Tab panes */}
          <TabContent className="following" activeTab={activeTab}>
            <TabPane tabId="1" id="booking" className="text-center">
              <Row>
                <Col className="ml-auto mr-auto" md="6">
                  <ul className="list-unstyled follows"> 
                    <ListBooking />
                  </ul>
                </Col>
              </Row>
            </TabPane>

            <TabPane className="text-center" tabId="2" id="following">
              <h3 className="text-muted">Tidak ada Notifikasi</h3>
              {/* <Button className="btn-round" color="warning">
                Find artists
              </Button> */}
            </TabPane>
          </TabContent>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default ProfilePage;

// const reduxState = (state) => ({
//   dataUser: state.detailUser.data.user[0]
// })

// export default connect(reduxState)(ProfilePage);

