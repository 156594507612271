/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import Cookies from 'universal-cookie';
//api url
import API_URL from '../../../ApiUrl';
import { loginUserAPI } from "./../../../config/redux/action"
import SweetAlert from 'sweetalert2-react';
import API from '../../../config/services';
import { Redirect } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

//function SectionLogin() {
const cookies = new Cookies();
class ChPassword extends Component {
  constructor(props){
    super(props);
    // cookies.set('kode_member',"");
    // cookies.set('token',"");
    // let base64 = require('base-64');

    this.state = {
      password:"",
      re_password:"",
      tokenFgp:"",
      error:{},
      loginstatus:"",
      inputBorder: "border: 1px solid #ca0b0b!important",
      showSwalError:false,
      showSwalError_msg:"",
      showSwalSuccess:false,
      showSwalSuccess_msg:"",

      btnDisabled: false,
      formAlertClassName:'',
    }
  }

  componentDidMount = () => {
    API.checkTokenFgp(this.props.match.params.tokenfgp).then((res) => {
      console.log('test token',res)
      if( res.status_token) {
        this.setState({
          tokenFgp: res.message
        })
      }else{
        // this.setState({
        //   tokenFgp: ''
        // })
        alert(res.message);
        window.location.href="/";
      }
    }, (err) => 
      //alert('Gagal Mendapatkan Data');
      <Redirect to="/notfound" />
    )
}
  
  handleValidation(){
    let errors = {};
    let formIsValid = true;
    
    //password
    if(!this.state.password){
        formIsValid = false;
        errors.password = "Password wajib diisi";
        this.inputPassword.style = this.state.inputBorder;
      }
    if(!this.state.re_password || this.state.re_password !== this.state.password){
        formIsValid = false;
        errors.re_password = "Password tidak sama";
        this.inputRePassword.style = this.state.inputBorder;  
    }
    this.setState({error:errors});
    return formIsValid;
  }

  submitButton(e){
    e.preventDefault();
    if(this.handleValidation()){
      this.sendData();
    }
  }

  closeSwalError(){
    this.setState({
      email_forgot_password:"",
      email: "",
      password: "",
      showSwalError1:false
    })
    if(this.state.linkForgotPassword){
      this.inputEmailForgotPassword.value = "";
    }else{
      this.inputEmail.value="";
      this.inputPassword.value="";
    }
  }

  sendData(){
    this.setState({
        message:"Proses simpan, mohon menunggu beberapa saat.",
        formAlert:{display:'block'},
        formAlertClassName:'alert alert-warning',
        btnDisabled: true
      });

    const RootPath = API_URL.url;
    var formData = new FormData();
    formData.append('token',this.state.tokenFgp);
    formData.append('password',this.state.password);

    axios({
        method:'POST',
        url:RootPath+"/member/editp",
        data:formData,
        // config:{headers:{'Content-Type':'application/x-www-form-urlencoded'}}
        config:{headers:{'Content-Type':'multipart/form-data'}}
      }).then((reply) => {
          console.log('wewewe',reply.data);
          if(reply.data.status === "SUCCESS"){
            this.setState({showSwalSuccess:true,showSwalSuccess_msg: reply.data.message, btnDisabled: false});

            // this.setState({
            //   message:reply.data[0].pesan,
            //   formAlert:{display:'block'},
            //   formAlertClassName:'alert alert-success',
            //   btnDisabled: false
            // });
          }else{
              this.setState({
                message:reply.data.message,
                formAlert:{display:'block'},
                formAlertClassName:'alert alert-warning',
                btnDisabled: false
              });
              console.log(reply.data)
          }
      }).catch(function(reply){
          console.log('wewewe2',reply.data);
      })
  }

  onBlurPassword(e){
    e.preventDefault()
    if(this.state.password.length > 0){
        this.inputPassword.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.password = '';                                 
            return { error };                   
        })
    }
  }
  onBlurRePassword(e){
    e.preventDefault()
    if(this.state.re_password === this.state.password){
        this.inputRePassword.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.re_password = '';                                 
            return { error };                   
        })
    }else{
      this.inputRePassword.style = this.state.inputBorder;
      this.setState(prevState => {
          let errors = Object.assign({}, prevState.error);  
          errors.re_password = 'Password tidak sama!';                                 
          this.setState({error:errors});                  
      })
    }
  }

  render() {
    var linkForgotPassword = this.state.linkForgotPassword;
    return (
      <>
        <div
          style={{
            backgroundColor: "black"
          }}
          className="page-header"
          data-parallax={true}
        >
          <Container>
            <Row>
              <Col className="mx-auto" lg="4" md="6">
                <Card className="card-register">
                  <h3 className="title mx-auto text-center"> New Password </h3>
                  <Form className="register-form">
                    <label>Password</label>
                    <InputGroup className="form-group-no-border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                        <input className="form-control" type="password"  id="inputPassword4" placeholder="Password" autoComplete="off"
                            onChange={(e) => this.setState({password:e.target.value})}
                            onBlur={(e) => this.onBlurPassword(e)}
                            ref={e => this.inputPassword = e}
                        />
                    </InputGroup>
                    <label className="error-label-register" style={{marginTop: '5px'}}>
                        {this.state.error.password}
                    </label><br/>

                    <label style={{marginTop:'15px'}}>Repeat Password</label>
                    <InputGroup className="form-group-no-border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                        <input className="form-control" type="password"  id="inputRePassword4" placeholder="Password" autoComplete="off"
                            onChange={(e) => this.setState({re_password:e.target.value})}
                            onBlur={(e) => this.onBlurRePassword(e)}
                            ref={e => this.inputRePassword = e}
                        />
                    </InputGroup>
                    <label className="error-label-register"  style={{marginTop: '5px'}}>
                          {this.state.error.re_password}
                    </label>

                    <Button
                      block
                      className="btn-round bgcolorgold"
                      //color="danger"
                      type="button"
                      onClick={e=>this.submitButton(e)}
                    >
                      Submit
                    </Button>

                    <Row style={{marginBottom:"20px"}}>
                      <Col>
                        <div id="message" className={this.state.formAlertClassName+" alert-dismissible"} style={this.state.formAlert}>
                          {this.state.message}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mx-auto text-center" md="12">
                        <div className="text-cente">
                          <a href="/" className="text-cente" style={{color:'#E3BE5A',fontWeight:"400", fontSize:"15px"}}>Kembali</a>
                       </div>
                      </Col>
                    </Row>

                    <label className="error-label-register">
                        {this.state.error.loginstatus}
                    </label>
                  </Form>
                </Card>
              </Col>
            </Row>
            <SweetAlert
              show={this.state.showSwalSuccess}
              confirmBtnText="Kembali"
              confirmBtnBsStyle="default"
              success
              title="Berhasil!"
              text={this.state.showSwalSuccess_msg}
              btnSize="md"
              onConfirm={() => 
                window.location.href='/login'
              }
            >
              {/* Silahkan cek email anda &#60;{this.state.email_forgot_password}&#62;,untuk reset password baru! */}
              {this.state.showSwalSuccess_msg}
            </SweetAlert>

            <SweetAlert
              show={this.state.showSwalError}
              confirmBtnText="Kembali"
              confirmBtnBsStyle="default"
              danger
              title="Error!"
              text={this.state.showSwalError_msg}
              btnSize="md"
              onConfirm={() => 
                this.closeSwalError()
              }
            >
            </SweetAlert>
          </Container>
        
        </div>{" "}
      </>
    );
  }
}

export default ChPassword;


// const reduxState = (state) => ({
//   dataUser: state.detailUser
// })

// const reduxDispatch = (dispatch) => ({
//  loginAPI: (data) => dispatch(loginUserAPI(data))
// })

// export default connect(reduxState, reduxDispatch)(Login);
