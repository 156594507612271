import axios from 'axios';
import API_URL from '../../ApiUrl';

const RootPath = API_URL.url;
const MasterPath = API_URL.master;

const Get = (path) => {
    const promise = new Promise((resolve, reject) => {
        axios.get(`${RootPath}/${path}`)
        .then((result) => {
            resolve(result.data);
        }, (err) => {
            reject(err);
        })
    })
    return promise;
}

const GetById = (path) => {
    const promise = new Promise((resolve, reject) => {
        axios.get(`${RootPath}/${path}`)
        .then((result) => {
            resolve(result.data);
            //console.log('testing: ', result.data);
        }, (err) => {
            reject(err);
        })
    })
    return promise;
}

const GetKonsultanById = (path) => {
    const promise = new Promise((resolve, reject) => {
        axios.get(`${MasterPath}/${path}`,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.masterKey}`
            }
        }).then((result) => {
            resolve(result.data);
            //console.log('testing: ', result.data);
        }, (err) => {
            reject(err);
        })
    })
    return promise;
}

const GetCalendlykonsultan = (path) => {
    const promise = new Promise((resolve, reject) => {
        axios.get(`${MasterPath}/${path}`,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.masterKey}`
            }
        }).then((result) => {
            resolve(result.data);
            //console.log('testing: ', result.data);
        }, (err) => {
            reject(err);
        })
    })
    return promise;
}

const GetByEmail = (path) => {
    const promise = new Promise((resolve, reject) => {
        axios.get(`${RootPath}/${path}`)
        .then((result) => {
            resolve(result.data);
            //console.log('testing: ', result.data);
        }, (err) => {
            reject(err);
        })
    })
    return promise;
}

const GetByToken = (path) => {
    const promise = new Promise((resolve, reject) => {
        axios.get(`${RootPath}/${path}`)
        .then((result) => {
            resolve(result.data);
            //console.log('testing: ', result.data);
        }, (err) => {
            reject(err);
        })
    })
    return promise;
}

const Post = (path, data) => {
    const promise = new Promise((resolve, reject) => {
        axios.post(`${RootPath}/${path}`, data).then((result) =>  {
            resolve(result);
        }, (err) => {
            reject(err);

        })
    })
    return promise;
}


//POST
// const login = (data, headers) => Post('member/login', data, headers);
const login = (data) => Post('member/login', data);
const logout = (data) => Post('member/logout', data);
// GET
const getListTransfer = (data) => GetById(`getmutasidonasilistbynewmember/${data}`);
const getDetailUser = (data) => GetById(`showmemberperid/${data}`);
const getKonsultanMember = (data) => GetKonsultanById(`konsultan/id/${data}`);
const getListBooking = (data) => GetById(`tmbooking/findbymember/${data}`);
const getDataProfile = (data, headers) => GetByEmail(`member/findbyemail/${data}`, headers);
const checkTokenFgp = (data, headers) => GetByToken(`member/findbytoken/${data}`, headers);
const getCalendlyLink = (kode, tipe) => GetCalendlykonsultan(`konsultan/calendlykonsultan/${kode}`);


const API = {
    login,
    logout,
    getListTransfer,
    getDetailUser,
    getKonsultanMember,
    getListBooking,
    getDataProfile,
    checkTokenFgp,
    getCalendlyLink
}

export default API;