import React , { Component } from 'react';
import { connect } from 'react-redux';
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from "react-component-export-image";
import axios from 'axios';
// reactstrap components
import { Button, Container, FormGroup, Label, Input, Modal } from "reactstrap";
import API_URL from '../../../ApiUrl';
// import VCardPrint from "./VCardPrint";
// core components


class VCardPrint extends Component {
  constructor(props) {
    super(props);
    // this.componentRef = React.createRef();
  }
  
  componentDidMount(){
  }
  render() {
    console.log('wewewew', this.props.dataM)
    const data = this.props.dataM;
    const id_member = this.props.dataM.id_member;
    return (
      <>
              <div className="row">
                  <div className="col">
                      <div className="row">
                        <div className="vcard" style={{ margin:"3px"}}>
                          <img src={require('./../../../assets/img/virtual-card/Mockup_Member_Card-02.png')} className="img-fluid" alt=""  />
                        </div>
                        
                        <div className="qrcode">
                          <div className="qrimg">
                            <img src={API_URL.url+"/member/qrcode/"+id_member} className="img-fluid" alt=""/>
                          </div>   
                        </div>

                        <div className="wrap-text">
                          <div className="namaMember"><span>{data.nama_lengkap}</span></div>   
                          <div className="kodeMember"><span>Member ID. {data.kode_member}</span></div>
                        </div>
                      </div>

                      {/* <div className="row">
                        <div className="bg-content-1 vcard">
                          <img src={require('./../../../assets/img/virtual-card/Virtual Card-02.png')} className="img-fluid" alt="" />
                        </div>
                      </div> */}
                  </div>
              </div>
      </>
    );
  }
}

class VCard extends React.Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
  }

  render() {
    return (
    <React.Fragment>
        <VCardPrint ref={this.componentRef} dataM={this.props.dataUser} />
        {/* <button onClick={() => exportComponentAsJPEG(this.componentRef)}>
            Download Vcard
        </button> */}
        <div className="text-center" style={{marginTop:"5px"}}>
          <Button className="btn-sm" onClick={() => exportComponentAsJPEG(this.componentRef)}>
            Download Vcard
          </Button>
        </div>
    </React.Fragment>);
  }
 }

 const reduxState = (state) => ({
  dataUser: state.detailUser.user[0]
})

export default connect(reduxState)(VCard);