/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import Cookies from 'universal-cookie';
//api url
import API_URL from '../../../ApiUrl';
import { loginUserAPI } from "./../../../config/redux/action"
import SweetAlert from 'sweetalert2-react';
import {SplashScreen} from './../../../components/molecules/';
// reactstrap components
import {
  Button,
  Card,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

//function SectionLogin() {
const cookies = new Cookies();
class Login extends Component {
  constructor(props){
    super(props);
    //cek login
    const session = sessionStorage.getItem('status_login');
      if( session ){
        window.location.href="/home";
      }
    this.state = {
      email:"",
      password:"",
      error:{},
      loginstatus:"",
      inputBorder: "border: 1px solid #ca0b0b!important",
      // kode_member: base64.decode(cookies.get('kode_member')),
      linkForgotPassword : false,
      email_forgot_password:"",
      showSwalError:false,
      showSwalError_msg:"",
      showSwalSuccess:false,
      showSwalSuccess_msg:"",

      showSwalError1:false,
      showSwalError_msg1:"",
      showSwalSuccess1:false,
      showSwalSuccess_msg1:"",

      btnDisabled: false,
      formAlertClassName:'',
      SplashScreenop: false
    }
  }
  
  handleValidation(){
    let errors = {};
    let formIsValid = true;
    
    //email
    if(!this.state.email){
        formIsValid = false;
        errors.email = "Email wajib diisi";
        this.inputEmail.style = this.state.inputBorder;
    }
    if(typeof this.state.email !== "undefined"){
        let lastAtPos  = this.state.email.lastIndexOf('@');
        let lastDotPos = this.state.email.lastIndexOf('.');
        if(!(lastAtPos < lastDotPos && lastAtPos > 0 && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)){
            formIsValid = false;
            errors.email = "Email tidak valid";  
            this.inputEmail.style = this.state.inputBorder;  
        }
    }
    
    //password
    if(!this.state.password){
      formIsValid = false;
      errors.password = "Password wajib diisi";
      this.inputPassword.style = this.state.inputBorder;
    }
    this.setState({error:errors});
    return formIsValid;
  }

  handleValidationForgotPassword(){
    let errors = {};
    let formIsValid = true;
    
    //email
    if(!this.state.email_forgot_password){
        formIsValid = false;
        errors.email_forgot_password = "Email wajib diisi";
        this.inputEmailForgotPassword.style = this.state.inputBorder;
    }
    if(typeof this.state.email_forgot_password !== "undefined"){
        let lastAtPos  = this.state.email_forgot_password.lastIndexOf('@');
        let lastDotPos = this.state.email_forgot_password.lastIndexOf('.');
        if(!(lastAtPos < lastDotPos && lastAtPos > 0 && lastAtPos > 0 && this.state.email_forgot_password.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email_forgot_password.length - lastDotPos) > 2)){
            formIsValid = false;
            errors.email_forgot_password = "Email tidak valid";  
            this.inputEmailForgotPassword.style = this.state.inputBorder;  
        }
    }
    
    this.setState({error:errors});
    return formIsValid;
  }

  loginSubmit(e){
    e.preventDefault();
    if(this.handleValidation()){
      this.sendData();
    }
  }

  onClickForgotPassword(){
    this.setState({
      linkForgotPassword: true
    });
  }

  closeSwalError(){
    this.setState({
      email_forgot_password:"",
      email: "",
      password: "",
      showSwalError1:false
    })
    if(this.state.linkForgotPassword){
      this.inputEmailForgotPassword.value = "";
    }else{
      this.inputEmail.value="";
      this.inputPassword.value="";
    }
  }

  sendData(){

    var base64 = require('base-64');//untuk enkripsi kode_member
    var formData = new FormData();
    let acak = this.generateRandomToken();
    formData.append('email',this.state.email);
    formData.append('password',this.state.password);
    formData.append('user_token',acak);


    // axios({
    //   method:'POST',
    //   url:api_url+"/member/login",
    //   data:formData,
    //   config:{headers:{'Content-Type':'multipart/form-data'}}
    // }).then((reply) => {
    //   console.log(reply);
    //   cookies.set('kode_member', base64.encode(reply.data.user[0].kode_member), {path:'/'})
    //   cookies.set('token', acak, {path:'/'})
      
    //   if(reply.data.status_payment){
    //     window.location.href="/home";
    //   }else if(!reply.data.status_payment){
    //     window.location.href="/infopayment";
    //   }
    // }).catch(function(reply){
    //   console.log(reply);
    // })


  
      const res = this.props.loginAPI(formData)
      .then((res) => {
        if(this.props.dataUser.status_login === true){
          // cookies.set('kode_member', base64.encode(this.props.dataUser.user[0].id_member), {path:'/'})
          // cookies.set('token', acak, {path:'/'})
          sessionStorage.setItem('status_login', this.props.dataUser.status_login);
          sessionStorage.setItem('status_payment', this.props.dataUser.status_payment);
          
          const {history} = this.props;
          const loginstatus = this.props.dataUser.response;
          if(this.props.dataUser.status_payment){
            // window.location.href="/home";
            this.setState({SplashScreenop: true});
            // setTimeout(this.setState({SplashScreenop: true}),1000);
            setTimeout(
              function() {
                this.setState({SplashScreenop: true});
              }
              .bind(this),
              0
            );
            setTimeout(
              function() {
                // history.push('/home');
                window.location.href="/home";
              }
              .bind(this),
              3000
            );
            
          }else if(!this.props.dataUser.status_payment){
            this.setState({SplashScreenop: true});
            // setTimeout(this.setState({SplashScreenop: true}),1000);
            setTimeout(
              function() {
                this.setState({SplashScreenop: true});
              }
              .bind(this),
              0
            );
            setTimeout(
              function() {
                window.location.href="/home";
                // history.push('/infopayment');
              }
              .bind(this),
              3000
            );
            // window.location.href="/infopayment";
            // history.push('/infopayment');
          }
        }else{
          alert(this.props.dataUser.response);
        }
        
      })
      // .catch(function(error){
      //   // const loginstatus = this.props.dataUser.response;
      //    console.log('cek login status', error);
      //   // this.setState({error:error})
      //   //  alert(error);
      // })
  }

  sendEmailForgotPassword(){
    if(this.handleValidationForgotPassword()){
      //alert(this.state.email_forgot_password);
      this.setState({
        message:"Proses simpan, mohon menunggu beberapa saat.",
        formAlert:{display:'block'},
        formAlertClassName:'alert alert-warning',
        btnDisabled: true
      });
      
      let email = this.state.email_forgot_password;
      const RootPath = API_URL.url;
      
      const formData = new FormData();
      formData.append('email',email) ;
      axios({
        method:'POST',
        url:RootPath+"/member/cekfgp",
        data:formData,
        config:{headers:{'Content-Type':'application/x-www-form-urlencoded'}}
      }).then((reply) => {
          console.log('wewewe',reply.data[0].pesan);
          if(reply.data[0].status === "Ada"){

            this.setState({showSwalSuccess1:true,showSwalSuccess_msg1: reply.data[0].pesan});

            this.setState({
              message:reply.data[0].pesan,
              formAlert:{display:'block'},
              formAlertClassName:'alert alert-success',
              btnDisabled: false
            });
          }else{
              //alert(reply.data.message);

              // this.setState({showSwalError1:true,showSwalError_msg1: reply.data[0].pesan, btnDisabled: false});


              this.setState({
                message:reply.data[0].pesan,
                formAlert:{display:'block'},
                formAlertClassName:'alert alert-warning',
                btnDisabled: false
              });
              console.log(reply.data)
          }
      }).catch(function(reply){
          console.log(reply);
      })
    }
  }

  generateRandomToken(){
    const min = 1;
    const max = 100;
    const rand = min + Math.random() * (max - min);
    return rand;
  }

  onBlurEmail(e){
    e.preventDefault()
    if(this.state.email.length > 5){
        this.inputEmail.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.email = '';                                 
            return { error };                   
        })
    }
  }

  onBlurPassword(e){
    e.preventDefault()
    if(this.state.password.length > 0){
        this.inputPassword.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.password = '';                                 
            return { error };                   
        })
    }
  }

  onBlurEmailForgotPassword(e){
    e.preventDefault()
    if(this.state.email_forgot_password.length > 5){
        this.inputEmailForgotPassword.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.email_forgot_password = '';                                 
            return { error };                   
        })
    }
  }

  render() {
    var test = this.state.SplashScreenop;
    if(test){
      return(
        <div style={{backgroundColor: "#333"}} className="page-header"data-parallax={true}>
          <Container>
            <Row>
              <Col className="mx-auto" lg="4" md="6">
              <Card className="card-register">
                  <Row style={{marginBottom:"20px"}}>
                    <Col>
                      <div>
                        <SplashScreen/>
                      </div>
                    </Col>
                  </Row>
                  
              </Card>
              </Col>
            </Row>
          </Container>
        </div>
        
      );  
  }
    var linkForgotPassword = this.state.linkForgotPassword;
    if(linkForgotPassword){
      return(
        <div style={{
          backgroundColor: "#333"
        }}
        className="page-header"
        data-parallax={true}>
          <Container>
            <Row>
              <Col className="mx-auto" lg="4" md="6">
                <Card className="card-sudah-login" >
                  <label>Silahkan masukkan email anda</label>
                  <input className="form-control" placeholder="Email" type="email" 
                    onChange={(e) => this.setState({email_forgot_password:e.target.value})}
                    onBlur={(e) => this.onBlurEmailForgotPassword(e)}
                    ref={e => this.inputEmailForgotPassword = e}
                  />
                  <label className="error-label-register" style={{marginTop: '5px'}}>
                        {this.state.error.email_forgot_password}
                    </label><br/>
                  <Button disabled={this.state.btnDisabled} style={{marginTop:"15px"}} onClick={()=>this.sendEmailForgotPassword()}>Kirim</Button>
                  <Row style={{marginBottom:"20px"}}>
                      <Col>
                        <div id="message" className={this.state.formAlertClassName+" alert-dismissible"} style={this.state.formAlert}>
                          {this.state.message}
                        </div>
                      </Col>
                    </Row>
                  <br/>
                  <a href="/login" style={{color:'#00aeef', textDecoration:"underlined"}}>Kembali</a>

                </Card>
              </Col>
            </Row>

            <SweetAlert
            show={this.state.showSwalSuccess1}
            confirmBtnText="Kembali"
            confirmBtnBsStyle="default"
            success
            title="Berhasil!"
            text={this.state.showSwalSuccess_msg1}
            btnSize="md"
            onConfirm={() => 
              window.location.href='/'
            }
          >
          </SweetAlert>

          <SweetAlert
            show={this.state.showSwalError1}
            confirmBtnText="Kembali"
            confirmBtnBsStyle="default"
            danger
            title="Error!"
            text={this.state.showSwalError_msg1}
            btnSize="md"
            onConfirm={() => 
              this.closeSwalError()
            }
          >
          </SweetAlert>
          </Container>
        </div>
      )
    }else{
    return (
      <>
        {/* <div
          className="section section-image section-login"
          style={{
            backgroundImage: "url(" + require("./../../../assets/img/login-image.jpg") + ")"
          }}
        > */}

        <div
          style={{
            backgroundColor: "black"
          }}
          className="page-header"
          data-parallax={true}
        >
          <Container>
            <Row>
              <Col className="mx-auto" lg="4" md="6">
                <Card className="card-register">
                  <h3 className="title mx-auto text-center"> NIEC Membership </h3>
                  <Form className="register-form">
                    <label>Email</label>
                    <InputGroup className="form-group-no-border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <input className="form-control" placeholder="Email" type="email" 
                        onChange={(e) => this.setState({email:e.target.value})}
                        onBlur={(e) => this.onBlurEmail(e)}
                        ref={e => this.inputEmail = e}
                      />
                    </InputGroup>
                    <label className="error-label-register" style={{marginTop: '5px'}}>
                        {this.state.error.email}
                    </label><br/>
                    <label style={{marginTop:'15px'}}>Password</label>
                    <InputGroup className="form-group-no-border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <input className="form-control" placeholder="Password" type="password"
                        onChange={(e) => this.setState({password:e.target.value})}
                        onBlur={(e) => this.onBlurPassword(e)}
                        ref={e => this.inputPassword = e}
                      />
                    </InputGroup>
                    <label className="error-label-register"  style={{marginTop: '5px'}}>
                          {this.state.error.password}
                    </label>

                    <Row>
                      <Col className="mx-auto text-center" md="12">
                        <span>Lupa Password ?</span>
                          <a
                            color="danger"
                            onClick={()=>this.onClickForgotPassword()} className="btn-link mr-1" style={{color:"#E3BE5A",fontWeight:"400",fontSize:"15px",textTransform:"none",cursor:"pointer"}}>
                            Klik Disini
                          </a>
                      </Col>
                    </Row>

                    <Button
                      block
                      className="btn-round bgcolorgold"
                      //color="danger"
                      type="button"
                      onClick={e=>this.loginSubmit(e)}
                    >
                      Login
                    </Button>

                    <Row>
                      <Col className="mx-auto text-center" md="12">
                        <div className="text-cente">
                          <a href="/" className="text-cente" style={{color:'#E3BE5A',fontWeight:"400", fontSize:"15px"}}>Kembali</a>
                       </div>
                      </Col>
                    </Row>

                    <label className="error-label-register">
                        {this.state.error.loginstatus}
                    </label>
                  </Form>
                </Card>
              </Col>
            </Row>
            <SweetAlert
              show={this.state.showSwalSuccess}
              confirmBtnText="Kembali"
              confirmBtnBsStyle="default"
              success
              title="Berhasil!"
              text={this.state.showSwalSuccess_msg}
              btnSize="md"
              onConfirm={() => 
                window.location.href='/'
              }
            >
              {/* Silahkan cek email anda &#60;{this.state.email_forgot_password}&#62;,untuk reset password baru! */}
              {this.state.showSwalSuccess_msg}
            </SweetAlert>

            <SweetAlert
              show={this.state.showSwalError}
              confirmBtnText="Kembali"
              confirmBtnBsStyle="default"
              danger
              title="Error!"
              text={this.state.showSwalError_msg}
              btnSize="md"
              onConfirm={() => 
                this.closeSwalError()
              }
            >
            </SweetAlert>
          </Container>
        
        </div>{" "}
      </>
    );
    }
  }
}

//export default Login;


const reduxState = (state) => ({
  dataUser: state.detailUser,
  dataKonsultan: state.KonsultanMember
})

const reduxDispatch = (dispatch) => ({
 loginAPI: (data) => dispatch(loginUserAPI(data))
})

export default connect(reduxState, reduxDispatch)(Login);
