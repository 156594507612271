/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import Cookies from 'universal-cookie';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
//import Datetime from 'react-datetime';
import SweetAlert from 'sweetalert2-react';
import { connect } from 'react-redux';
import Datetime from "react-datetime";
import Moment from "moment";
import { InlineWidget } from "react-calendly";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { StyleRoot } from 'radium';

import ReactDOM from 'react-dom';
import Coverflow from 'react-coverflow';

import { HomeHeader, MemberNavbar, DemoFooter, Vexpo, InfoSession, Openhouse } from './../../../components/molecules/';
import { grey } from "ansi-colors";
import classnames from "classnames";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { listBookingUpdate } from "./../../../config/redux/action"
import { Link } from "react-router-dom";

const cookies = new Cookies();
class KonsultasiZoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
        renderCalendlyKonsultan:"none",
        renderCalendlyHR:"none",
        errorMsgTopik:"none",
        ShowerrorMsgTopik:false,
        selectedTopik: ""
    };
  }

  setTopik(e){
    e.preventDefault();
    if(e.target.value === 'hr'){
        this.setState({
            selectedTopik:e.target.value,
            renderCalendlyHR:"",
            renderCalendlyKonsultan:"none"
        })
        console.log('cek hr', this.state.linkHR);
    }else if(e.target.value === 'konsultan'){
        this.setState({
            selectedTopik:e.target.value,
            renderCalendlyHR:"none",
            renderCalendlyKonsultan:""
        })
        console.log('cek konsultan', this.props.dataKonsultan[0].link_embed);
    }else{
        this.setState({
            selectedTopik:"",
            renderCalendlyHR:"none",
            renderCalendlyKonsultan:"none"
        })
    }
  }

  renderCalendlyKonsultan(){
    return(
        <div style={{display:this.state.renderCalendlyKonsultan}}>
            <FormGroup style={{marginBottom:"0px"}}>
                <InlineWidget url={this.props.dataKonsultan[0].link_embed} />
            </FormGroup>
        </div>
    );
        
  }
  
  renderCalendlyHR(){
    return(
        <div style={{display:this.state.renderCalendlyHR}}>
            <FormGroup style={{marginBottom:"0px"}}>
                <InlineWidget url={this.props.linkHR} />
                testing
            </FormGroup>
        </div>
    );
  }
    render() {
        return(
            <div className="section page-header section-dark section-nucleo-icons" style={{ backgroundColor: 'black' }}>
                <Container>
                    <Row>
                        <Col lg="6" md="12">
                        <h2 className="title1" style={{ fontWeight: 'bold' }}>Booking Konsultasi Online</h2>
                        <h5 style={{ fontWeight: '400', color:'white' }}>Konsultasi online bersama konsultan NIEC menggunakan platform Zoom. untuk menanyakan seputar kuliah keluar negeri maupun seputar test Talent Mapping
                        </h5>
                        <br />
                        <Label for="topik" style={{ color: 'white', fontWeight:'500'}}>Coming Soon</Label>
                            {/* <FormGroup style={{marginBottom:"0px"}}>
                                <Label for="topik" style={{ color: 'white', fontWeight:'500'}}>Pilih Topik Konsultasi</Label>
                                <select md="3" className="form-control" type="code_kelas"  id="code_kelas" placeholder=""
                                    onChange={(e) => this.setTopik(e)}
                                    // onBlur={(e) => this.onBlurTopik(e)}
                                    ref={e => this.inputTopik = e}
                                >
                                    <option value="0"></option>
                                    <option key="konsultan" value="konsultan">Seputar Kuliah Keluar Negeri</option>
                                    <option key="hr" value="hr">Seputar Talent Mapping</option>
                                </select>
                                <label className="error-label-register">
                                    {this.state.ShowerrorMsgTopik}
                                </label>
                            </FormGroup> */}
                        </Col>

                        <Col lg="6" md="12">
                            {this.renderCalendlyHR()}
                            {this.renderCalendlyKonsultan()}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}


const reduxState = (state) => ({
  dataKonsultan: state.KonsultanMember,
  dataUser: state.detailUser.user,
  linkHR: state.linkHR[0].link_embed
})

const reduxDispatch = (dispatch) => ({
  updateList: (data) => dispatch(listBookingUpdate(data))
 })
 
 export default connect(reduxState, reduxDispatch)(KonsultasiZoom);
