import React , { Component } from 'react';
import {
 NavLink
} from 'react-router-dom';

// reactstrap components
import { Button, Container, Row, Col, Card, CardBody, Input, FormGroup, Label } from "reactstrap";
import classnames from "classnames";
import axios from 'axios';
import API from '../../../config/services';
import leftPad from 'left-pad';
import { bold } from 'ansi-colors';


class ContactListingForm extends Component {
    state = {
        formContact: {
          name: '',
          eMail: '',
          phone_number: '',
        },
        errors: {
            name: false,
            eMail: false,
            phone_number: false,
            message: false,
        },
        message: '',
      }


    // postdataContact = () => {
    //     API.postNewContact(this.state.formContact).then((res) => {
         
    //     })
    // }

    handleFormChange = (event) => {
        //console.log('form change', event.target)
        let formContactNew = {...this.state.formContact};
        //let name = event.target.value
        formContactNew[event.target.name] = event.target.value;
        this.setState({
            formContact: formContactNew
        })
    }

    // handleOnChange = event => {
    //     this.setState({
    //       inputs: {
    //         ...this.state.inputs,
    //         [event.target.name]: event.target.value
    //       },
    //       errors: {
    //         ...this.state.errors,
    //         [event.target.name]: false
    //       }
    //     });
    //   };



    postDataToAPI = () => {
        // const headers = {
        //     'Content-Type': 'application/json',
        //      'Access-Control-Allow-Origin': '*',
        // }
        
        API.postNewContact(this.state.formContact).then((res) => {
                if (res = true) {
                    // alert("Data Berhasil Dikirim")
                    let formContact = {};
                    this.setState({
                        formContact: {
                            name: '',
                            eMail: '',
                            phone_number: '',
                            //message: 'Data Berhasil Dikirim'
                        },
                        message: "Data Berhasil Dikirim"
                    })
                    alert("Data Berhasil Dikirim")
                    //this.setState({ message: "Message sent successfully." });
                    

                  
                }else if (res = false) {
                    //this.setState({ message: "Send Problem." });
                    alert("Data Tidak Dapat Dikirim")
                }
            // this.setState({
            //     formContact: {
            //         name: '',
            //         eMail: '',
            //         phone_number: ''
            //     }
            // })
        }) 
        axios.post()
    }

    // postDataToAPI = () => {
    //     const headers = {
    //         'Content-Type': 'application/json',
    //          'Access-Control-Allow-Origin': '*',
    //       };

    //     axios.post('http://limonasi.com:8019/api/postcontactlisting', this.state.formContact, {headers}
    //       ).then((res) =>{
    //         console.log(res);
    //     }, (err) => {
    //         console.log('error: ', err);
    //     })
        
    // }

    handleSubmit = (event) => {
        event.preventDefault();
        //const { formContact, errors } = this.state;
        //create new errors object
        if (this.validateForm()) {
            // let formContact = {};
            // formContact["name"] = "";
            // formContact["eMail"] = "";
            // formContact["phone_number"] = "";
            // this.setState({formContact:formContact});
            //alert("Form submitted");
            this.postDataToAPI();
        }
  
        //console.log(this.state.formContact);
    } 

    validateForm() {

        let fields = this.state.formContact;
        let errors = {};
        let formIsValid = true;
  
        if (!fields["name"]) {
          formIsValid = false;
          errors["name"] = "*Please enter your name.";
        }
  
        if (typeof fields["name"] !== "undefined") {
          if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
            formIsValid = false;
            errors["name"] = "*Please enter alphabet characters only.";
          }
        }
  
        if (!fields["eMail"]) {
          formIsValid = false;
          errors["eMail"] = "*Please enter your email.";
        }
  
        if (typeof fields["eMail"] !== "undefined") {
          //regular expression for email validation
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(fields["eMail"])) {
            formIsValid = false;
            errors["eMail"] = "*Please enter valid email.";
          }
        }
  
        if (!fields["phone_number"]) {
          formIsValid = false;
          errors["phone_number"] = "*Please enter your mobile no.";
        }
  
        // if (typeof fields["phone_number"] !== "undefined") {
        //   if (!fields["phone_number"].match(/^[0-9]{10}$/)) {
        //     formIsValid = false;
        //     errors["phone_number"] = "*Please enter valid mobile no.";
        //   }
        // }
  
        this.setState({
          errors: errors
        });
        return formIsValid;
  
  
      }




    // handleOnSubmit = event => {
    //     event.preventDefault();
    //     const { inputs, errors } = this.state;
    //     //create new errors object
    //     let newErrorsObj = Object.entries(formContact)
    //       .filter(([key, value]) => {
    //         return value.length === 0;
    //       })
    //       .reduce((obj, [key, value]) => {
    //         if (value.length === 0) {
    //           obj[key] = true;
    //         } else {
    //           obj[key] = false;
    //         }
    //         return obj;
    //       }, {});
    
    //     if (Object.keys(newErrorsObj).length > 0) {
    //       this.setState({
    //         errors: newErrorsObj
    //       });
    //     }
    //   };




    render() {
        const { formContact, errors, message } = this.state;
        return(
            <Container>
            <div className="motto text-center">
                <Row>
                    <Col md="6">
                        <img
                            alt="logo-limonasi"
                            className={classnames("limonasi-logo-motto")}
                            src={require('./../../../assets/img/limonasi-logo/Logo-1-09.png')}
                            />
                            <h1 className="title-custom">LIMA DONASI</h1>
                            <h3 className="title-custom2">PLATFORM</h3>
                            <br />
                    </Col>

                    <Col md="6">
                        <Card className="card-plain">
                            <CardBody>
                                <p className="card-description" style={{ fontSize:"11pt", color:"white" }}>
                                    Sebuah platform Saling Membantu dengan konsep unik.
                                    Layaknya sebuah Komunitas yang saling bahu membahu, 
                                    Kami melengkapi platform ini dengan Donasi antar member, 
                                    pembagian produk-produk gratis, dan berbagi dalam kegiatan sosial kemanusiaan bagi yang membutuhkan, 
                                    dan sebagainya. Semua dalam platform yang sangat sederhana yang bisa diikuti oleh siapa saja. 
                                    Tanpa Level, Tanpa Tutup Poin Bulanan, Tanpa Pertemuan - Pertemuan, dan bukan PONZI.
                                </p>

                                <p className="card-description" style={{ fontSize:"11pt", color:"white" }}>
                                    Ingin jadi pertama yang mendapatkan notifikasi? Silahkan isi formulir berikut:
                                </p>

                                <div className="form-row" style={{ marginTop: "30px" }}>
                                    <FormGroup className={errors.name ? "col-md-6 has-danger" : "col-md-6"}>     
                                        <Label style={{ fontSize:"10pt", color:"white", fontWeight: "bolder" }} for="inputNama">Nama</Label>                        
                                        <Input type="name" name="name"  id="inputName" value={this.state.formContact.name} onChange={this.handleFormChange}/>
                                        <div className="errorMsg" style={{ fontSize:"10pt", color:"white" }}>{this.state.errors.name}</div>
                                    </FormGroup>

                                    <FormGroup className={errors.name ? "col-md-6 has-danger" : "col-md-6"}>
                                    <   Label style={{ fontSize:"10pt", color:"white", fontWeight: "bolder" }} for="inputEmail">Email</Label> 
                                        <Input type="email" name="eMail"  id="inputEmail" value={this.state.formContact.eMail} onChange={this.handleFormChange}/>
                                        <div className="errorMsg" style={{ fontSize:"10pt", color:"white" }}>{this.state.errors.eMail}</div>
                                    </FormGroup>
                                </div>

                                    <FormGroup className={errors.name ? "has-danger" : ""}>
                                        <Label style={{ fontSize:"10pt", color:"white", fontWeight: "bolder" }} for="inputNo">No Telepon / WA</Label>     
                                        <Input type="text" name="phone_number"  id="inputPhone" value={this.state.formContact.phone_number} onChange={this.handleFormChange}/>
                                        <div className="errorMsg" style={{ fontSize:"10pt", color:"white" }}>{this.state.errors.phone_number}</div>
                                    </FormGroup> 

                            
                                    <FormGroup>                             
                                        <Button size="lg" block type="submit" style={{ backgroundColor:"#E9BD43", border:"none" }} onClick={this.handleSubmit}>KIRIM SEKARANG</Button>
                                        {/* <div className={message ? "alert alert-success" : "alert"} role="alert" timeout="5">
                                            {this.state.message}
                                        </div> */}
                                    </FormGroup>
                            
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* <div className={errors ? "alert alert-success" : "alert"} role="alert">
                {this.state.message}
            </div> */}


            </Container>
        );
        }

}

export default ContactListingForm;
