import React, { Component } from "react";
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { logout } from "./../../../config/redux/action"
import { connect } from 'react-redux';

const cookies = new Cookies();
class Logout extends Component {
    constructor(props){
      super(props);
  }

  componentDidMount(){
    const { history } = this.props;
  
    var formData = new FormData();
    formData.append('id_member',this.props.dataUser.id_member);

    const res = this.props.logOut(formData);

    sessionStorage.setItem('status_login', false);
    sessionStorage.setItem('status_payment', false);
    //history.push('/');

    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
    // history.push('/');

    // console.log('cek logout', this.props.dataUser.id_member)

  }


  render() {
    return(
<div></div>
    );
  };
}

// Logout.propTypes = {
//     history: PropTypes.object
//   };

//export default Logout;

const reduxState = (state) => ({
  dataUser: state.detailUser.user[0]
})

const reduxDispatch = (dispatch) => ({
 logOut: (data) => dispatch(logout(data))
})

export default connect(reduxState, reduxDispatch)(Logout);