import React , { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
    Input,
    Row,
    Col,
    FormGroup,
    Label,
  } from "reactstrap";
//   import ReactPixel from 'react-facebook-pixel';
//import SweetAlert from 'sweetalert2-react';
import API_URL from  './../../../ApiUrl';
import SweetAlert from 'sweetalert2-react';

class Vexpo extends Component {
  constructor(props) {
    super(props);
    this.state = {
        id_member: this.props.dataUser[0].id_member,
        nama: this.props.dataUser[0].nama_lengkap,
        notelp: this.props.dataUser[0].no_wa,
        email: this.props.dataUser[0].email,
        kotaAsal:"",
        rekomendasi:"1",
        kategori:"3",
        sekolah: this.props.dataUser[0].nama_sekolah,
        kelas:this.props.dataUser[0].kelas_semester,
        tipePeserta: "4",
        checkemail:"",
        pesanEmailError:"",

        id_propinsi:"",
        id_kabkota:"",
        dataPropinsi: [],
        dataKabkota: [],
        dataKabkotafilter:[],

        message:"Error !",
        error: {},
        formAlert:{display:'none'},
        formAlertClassName:'alert alert-warning',
        inputBorder: "border:1px solid #ca0b0b;",

        idParent : "",
        dataKategori: [],
        dataTipepeserta: [],
        dataRekomendasi: [],
        kodeMember: "",
        checkkodemember: "",
        pesanKodeMember:"",
        showSwal: false,
        hide:"",

        btnDisabled: false
    };
  }

    handleValidation(){
        let errors = {};
        let formIsValid = true;
         //pripinsi
        if(!this.state.id_propinsi){
            formIsValid = false;
            errors.id_propinsi = "Provinsi wajib diisi";
            this.inputPropinsi.style = this.state.inputBorder;
        }
        //kabkota
        if(!this.state.id_kabkota){
            formIsValid = false;
            errors.id_kabkota = "Kabupaten / Kota wajib diisi";
            this.inputKabKota.style = this.state.inputBorder;
        }

        this.setState({error:errors});
        return formIsValid;
    }

    registrationSubmit(e){
        e.preventDefault();
            if(this.handleValidation()){
                this.sendData();
            }else{
                this.setState({
                    formAlert:{display:'unset'}, 
                    formAlertClassName:'alert alert-warning',
                    message:"Error ! Mohon cek kembali isian data",
                    hide: "none"
                })
            }
    }

    
    hideAlert(){
        setTimeout(
            function() {
                this.setState({message: "",formAlert:{display:'none',transition:'0.5s ease'}});
            }
            .bind(this),
            5000
        );  
    }

    onBlurPropinsi(e){
        e.preventDefault()
        if(this.state.id_propinsi.length > 2){
            this.inputPropinsi.style = "";
            this.setState(prevState => {
                let error = Object.assign({}, prevState.error);  
                error.id_propinsi = '';                                 
                return { error };                   
            })
        }
      }
      onBlurKabkota(e){
        e.preventDefault()
        if(this.state.id_kabkota.length > 2){
            this.inputKabKota.style = "";
            this.setState(prevState => {
                let error = Object.assign({}, prevState.error);  
                error.id_kabkota = '';                                 
                return { error };                   
            })
        }
      }

    clearInput() {
        this.inputKotaAsal.value = "";
    }

      checkEmail(){
        const url = API_URL.vexpo+'/peserta/cekmail';

        var formData = new FormData();
        formData.append('email',this.state.email);
        axios({
            method: 'post',
            url: url,
            data:formData,
            config:{headers:{'Content-Type': 'multipart/form-data'}}
        }).then(response => response.data)
        .then((data) => {
            this.setState({ checkemail: data[0].status, pesanEmailError: data[0].pesan })
            // console.log('cek data email', this.state.checkemail)
            // console.log('cek pesan email', this.state.pesanEmailError)
            console.log('cek email1', this.state.pesanEmailError)
        })
      }

    componentDidMount() {
        //ReactPixel.init('552257272349272');
        // ReactPixel.pageView(); // For tracking page view
        // ReactPixel.track(event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
        // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
        // ReactPixel.trackCustom(event, data); // For tracking custom events
        // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events
    
        this.checkEmail();
        this.getDataPropinsi();
        this.getDataKabkota();
        
    }

    getDataPropinsi(){
        const url = API_URL.master+'/propinsi';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
            }
        }).then(response => response.data)
        .then((data) => {
            this.setState({ dataPropinsi: data })
            console.log(this.state.dataPropinsi)
            // console.log('lalala',this.state.dataQuestion[0])
        })
      }
    
      getDataKabkota(){
        const url = API_URL.master+'/kabkota';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
            }
        }).then(response => response.data)
        .then((data) => {
            this.setState({ dataKabkota: data })
            console.log(this.state.dataKabkota)
        })
      }

      setPropinsi(e){
        e.preventDefault();
          this.setState({
              id_propinsi:e.target.value
          })
    
          const data = Object.values(this.state.dataKabkota);
          const id = e.target.value;
          const filterData = data.filter(dataFilter => {
            return dataFilter.id_propinsi == id;
            });
          this.setState({dataKabkotafilter:filterData})
          console.log(filterData)
      }
    
      setKabkota(e){
        e.preventDefault();
          this.setState({
              id_kabkota:e.target.value
          })
          console.log(this.state.id_kabkota)
      }

      radioChangeHandler = (event) =>{
        this.setState({
            konsultan:event.target.value
        })
    }

    onSchedule1Changed = (e) => {        
        const id = e.currentTarget.value
            this.setState(prevState => {
                let jadwalPilih = Object.assign({}, prevState.jadwalPilih);  
                jadwalPilih.jadwal1 = id;                                 
                return { jadwalPilih };                   
            }, () => {this.combineJadwal()})
    }
    onSchedule2Changed = (e) => {
        const id = e.target.value
            this.setState(prevState => {
                let jadwalPilih = Object.assign({}, prevState.jadwalPilih);  
                jadwalPilih.jadwal2 = id;                                 
                return { jadwalPilih };                   
            }, () => {this.combineJadwal()})
    }
    onSchedule3Changed = (e) => {
        const id = e.target.value
            this.setState(prevState => {
                let jadwalPilih = Object.assign({}, prevState.jadwalPilih);  
                jadwalPilih.jadwal3 = id;                                 
                return { jadwalPilih };                   
            }, () => {this.combineJadwal()})
    }
    onSchedule4Changed = (e) => {
        const id = e.currentTarget.value
            this.setState(prevState => {
                let jadwalPilih = Object.assign({}, prevState.jadwalPilih);  
                jadwalPilih.jadwal4 = id;                                 
                return { jadwalPilih };                   
            }, () => {this.combineJadwal()})
    }
    onSchedule5Changed = (e) => {
        const id = e.currentTarget.value
            this.setState(prevState => {
                let jadwalPilih = Object.assign({}, prevState.jadwalPilih);  
                jadwalPilih.jadwal5 = id;                                 
                return { jadwalPilih };                   
            }, () => {this.combineJadwal()})
    }
    onSchedule6Changed = (e) => {
        const id = e.currentTarget.value
            this.setState(prevState => {
                let jadwalPilih = Object.assign({}, prevState.jadwalPilih);  
                jadwalPilih.jadwal6 = id;                                 
                return { jadwalPilih };                   
            }, () => {this.combineJadwal()})
    }
    onSchedule7Changed = (e) => {
        const id = e.currentTarget.value
            this.setState(prevState => {
                let jadwalPilih = Object.assign({}, prevState.jadwalPilih);  
                jadwalPilih.jadwal7 = id;                                 
                return { jadwalPilih };                   
            }, () => {this.combineJadwal()})
    }
    onSchedule8Changed = (e) => {
        const id = e.currentTarget.value
            this.setState(prevState => {
                let jadwalPilih = Object.assign({}, prevState.jadwalPilih);  
                jadwalPilih.jadwal8 = id;                                 
                return { jadwalPilih };                   
            }, () => {this.combineJadwal()})
    }

    combineJadwal(){
        const data = Object.values(this.state.jadwalPilih)
          const filterData = data.filter(dataFilter => {
            return dataFilter !== "0";
            });
        this.setState({checkJadwal1:filterData})
            console.log('testing-log',filterData)
            console.log('testing-state',this.state.checkJadwal1)
            // alert(test.replace(/\"/g, ""));
            console.log('cek-combine',filterData)
    }

    sendData(){
      this.setState({
          message:"Proses simpan, mohon menunggu beberapa saat.",
          formAlert:{display:'block'},
          formAlertClassName:'alert alert-warning',
          btnDisabled: true
      });

        const url = API_URL.vexpo+'/peserta/registermember';
        
        var formData = new FormData();
        formData.append('id_member',this.state.id_member);
        formData.append('nama_lengkap',this.state.nama);
        formData.append('no_wa',this.state.notelp);
        formData.append('email',this.state.email);
        formData.append('sekolah_kampus',this.state.sekolah);
        formData.append('kelas_semester',this.state.kelas);
        formData.append('id_propinsi',this.state.id_propinsi);
        formData.append('id_kabkota',this.state.id_kabkota);

        axios({
            method: 'post',
            url: url,
            data:formData,
            config:{headers:{'Content-Type': 'multipart/form-data'}}
        }).then((response)=>{
            console.log('testing',response.data);
            // if(response.data[0].id_register !== 0){
            if(response.data === "ok"){
                this.hideAlert();
                this.setState({btnDisabled:false, showSwal:true})
            }else{
                this.setState({
                    // message:"Terjadi error di server! (102)",
                    message:response.data,
                    formAlert:{display:'block'},
                    formAlertClassName:'alert alert-danger',
                    btnDisabled:false,
                });
            }
        }).catch(function(response){
            console.log(response);
            this.setState({
                message:"Terjadi error di server! (101)",
                formAlert:{display:'block'},
                formAlertClassName:'alert alert-danger'
            });
        })
    }

    render() {
        if(this.state.checkemail === "Tolak"){
            return (
                <section className="section" id="register" style={{backgroundColor:"transparent"}}>
                
                <div className="container">
                    <div className="row">
                    {/* <EdufestSchedule /> */}
                        <div className="col-lg-8 offset-lg-2">
                            <h1 className="section-title text-center">REGISTER HERE</h1>
                            <div className="section-title-border margin-t-20"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="custom-form mt-4 pt-4">
                                <form method="post" action="" name="contact-form" id="contact-form">
                                    <div className="row">
                                        <div className="col-lg">
                                        <Label style={{ color: 'white', fontWeight:'500'}}>Anda telah melakukan pendaftaran sebelumnya.</Label><br /><br />

                                       
                                        </div>
                                    </div>

        
                                    <div className="row" style={{marginBottom:"20px"}}>
                                        <div className="col-lg-12">
                                            <div id="message" className={this.state.formAlertClassName+" alert-dismissible"} style={this.state.formAlert}>
                                                {this.state.message}
                                            </div>
                                        </div>
                                    </div>

                                    <a href="/home" style={{color:'#00aeef', textDecoration:"underlined", display:this.state.hide}}>Kembali</a>
                                </form>
                            </div>  
                        </div>
                    </div>
                </div>
                
            </section> 
            );
        }else{
            return (
                <section className="section" id="register" style={{backgroundColor:"transparent"}}>
                <div className="container">
                    <div className="row">
                    {/* <EdufestSchedule /> */}
                        <div className="col-lg-8 offset-lg-2">
                            <h1 className="section-title text-center">REGISTER HERE</h1>
                            <div className="section-title-border margin-t-20"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="custom-form mt-4 pt-4">
                                <form method="post" action="" name="contact-form" id="contact-form">
                                    <div className="row">
                                        <div className="col-lg">
                                        <Label style={{ color: 'white', fontWeight:'500'}}>Lengkapi isian berikut untuk pendaftaran Virtual Expo, pilih propinsi dan kota anda berasal.</Label><br /><br />
                                        <FormGroup style={{marginBottom:"0px"}}>
                                            <Label for="id_propinsi" style={{ color: '#7A7A7A', fontWeight:'500'}}>Provinsi</Label>
                                            <select md="3" className="form-control" type="id_propinsi"  id="id_propinsi" placeholder="Propinsi"
                                                // onChange={(e) => this.setState({id_propinsi:e.target.value})}
                                                onChange={(e) => this.setPropinsi(e)}
                                                onBlur={(e) => this.onBlurPropinsi(e)}
                                                ref={e => this.inputPropinsi = e}
                                            >
                                                <option value="0">--Pilih Propinsi--</option>
                                                {this.state.dataPropinsi.map((data1)=>(
                                                <option key={data1.id_propinsi} value={data1.id_propinsi}>{data1.nama_propinsi}</option>
                                                ))}
                                            </select>
                                            <label className="error-label-register">
                                                {this.state.error.id_propinsi}
                                            </label>
                                        </FormGroup>

                                        <FormGroup style={{marginBottom:"20px"}}>
                                            <Label for="id_kabkota" style={{ color: '#7A7A7A', fontWeight:'500'}}>Kab / Kota</Label>
                                            <select md="3" className="form-control" type="id_kabkota"  id="id_kabkota" placeholder="Kab / Kota"
                                                // onChange={(e) => this.setState({id_kabkota:e.target.value})}
                                                onChange={(e) => this.setKabkota(e)}
                                                onBlur={(e) => this.onBlurKabkota(e)}
                                                ref={e => this.inputKabKota = e}
                                            >
                                                <option value="0">--Pilih Kab/Kota--</option>
                                                {this.state.dataKabkotafilter.map((data2)=>(
                                                <option key={data2.id_kabkota} value={data2.id_kabkota}>{data2.nama_kabkota}</option>
                                                ))}
                                            </select>
                                            <label className="error-label-register">
                                                {this.state.error.id_kabkota}
                                            </label>
                                        </FormGroup>
                                        </div>
                                    </div>

        
                                    <div className="row" style={{marginBottom:"20px"}}>
                                        <div className="col-lg-12">
                                            <div id="message" className={this.state.formAlertClassName+" alert-dismissible"} style={this.state.formAlert}>
                                                {this.state.message}
                                            </div>
                                        </div>
                                    </div>

                                    <a href="/home" style={{color:'#00aeef', textDecoration:"underlined", display:this.state.hide}}>Kembali</a>
                                    <div className="row">
                                        <div className="col-lg-12 text-right">
                                            <input disabled={this.state.btnDisabled} type="submit" id="submit" name="register" onClick={e=>this.registrationSubmit(e)} className="submitBnt btn btn-custom" value="Submit" />
                                            <div id="simple-msg"></div>
                                        </div>
                                    </div>

                                    <SweetAlert
                                        show={this.state.showSwal}
                                        title="Selamat"
                                        text="Anda Berhasil Mendaftar"
                                        icon= 'success'
                                        confirmButtonText="Kembali"
                                        onConfirm={() => 
                                        window.location.href="/home"
                                        }
                                    />
                                </form>
                            </div>  
                        </div>
                    </div>
                </div>
            </section>
            );
        }
    }
}
// export default Register;

const reduxState = (state) => ({
  dataUser: state.detailUser.user
})
 
 export default connect(reduxState)(Vexpo);
