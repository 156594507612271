/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import Cookies from 'universal-cookie';
import axios from 'axios';
import API_URL from '../../../ApiUrl';
import { connect } from 'react-redux';

import {PaymentNavbar} from './../../../components/molecules/';

// reactstrap components
import {
  Button,
  Card,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

//function SectionLogin() {
const cookies = new Cookies();
class InfoPayment extends Component {
  constructor(props){
    super(props);
        // const payment = sessionStorage.getItem('status_payment');
        // if(payment === true){
        //   window.location.href="/home";
        // }
    this.state = {
      id_member:this.props.dataUser.id_member,
      selectedFile:"",
      dataMember:this.props.dataUser,
      isOpenImgVwr:false,
      harga:[],
      btnDisabled: false,
      formAlertClassName:'',
    }
  }
  fileSelect = event => {
    this.state.selectedFile = event.target.files[0];
    console.log(this.state);
  }
  fileUpload = () => {
    this.setState({
      message:"Proses simpan, mohon menunggu beberapa saat.",
      formAlert:{display:'block'},
      formAlertClassName:'alert alert-warning',
      btnDisabled: true
    });
    const formData = new FormData();
    formData.append('id_member', this.state.id_member);
    formData.append('image', this.state.selectedFile, this.state.selectedFile.name);
    axios({
      method:'POST',
      url:API_URL.url+"/member/upload",
      data:formData,
      config:{headers:{'Content-Type':'multipart/form-data'}}
    }).then((reply) => {
      console.log(reply);
      if(reply.data === "ok"){
        alert('Berhasil');
        window.location.href = "/infopayment";
      }else{
        alert('Gagal');
      }
    }).catch(function(reply){
      console.log(reply);
    })
    
  }
  onFormSubmit(e){
    e.preventDefault();
    this.fileUpload();
  }
  componentDidMount(){
    // this.checkIssetPayment();
    this.checkHarga();
  }
  // checkIssetPayment(){
  //   let lValid = true;
  //   const idMember = this.state.id_member;
  //   const url = API_URL.url+'/member/find_by_id/'+idMember;
  //   axios.get(url,{
  //       headers: {
  //           'Accept': 'application/json',
  //       }
  //   }).then(response => response.data)
  //   .then((data) => {
  //       this.setState({ dataMember: data[0] })
  //       console.log(this.state);
  //       if(data[0].status_payment === "Y" && data[0].bukti_payment !== ""){
  //         window.location.href = "/home";
  //       }
  //   });
  // }

  checkHarga(){
    const id_harga = this.props.idHarga;
    //console.log('cekid', id_harga);
    const url = API_URL.url+'/member/cek_harga/'+id_harga;
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
        }
    }).then(response => response.data)
    .then((data) => {
      this.setState({ harga: data[0] })
        console.log('cekharga', this.state.harga);
    });
  }
  
  render() {
  
  let uploadDiv;
  let uploadText;
  if(this.state.dataMember.bukti_payment !== ""){
    uploadText = "Edit";
    uploadDiv = (
      <div className="row">
        <div className="col-md-12">
          <h4 className="title1" style={{ fontWeight: 'bold', fontSize:'15pt' }}>Bukti Pembayaran : </h4>
          <div className="bukti-payment-container">
            <img src={API_URL.url+"/member/bukti_payment/"+this.state.id_member}/>
          </div>
        </div>
      </div>  
    );
  }else{
    uploadText = "Upload";
    uploadDiv = "";
  }
  return (
    <>
      {/* <div
        className="section section-image section-login"
        style={{
          backgroundImage: "url(" + require("./../../../assets/img/login-image.jpg") + ")"
        }}
      > */}
      <PaymentNavbar />
      <div
        style={{
          backgroundColor: "black",
          maxHeight:"unset"
        }}
        className="page-header"
        data-parallax={true}
      >
        <Container>
          {/* <div className="motto text-center">
            <img
              alt="logo-membership"
              className={classnames("membership-logo-motto")}
              src={require('./../../../assets/img/niec-logo/Logo_Member-01.png')}
            />
          </div> */}
            <div className="row" style={{ padding:"40px" }}>
                <div className="col-lg-3 offset-lg-2 text-center bg-content-1-container">
                    <div className="bg-content-1">
                        <img src={require('./../../../assets/img/niec-logo/Logo_Member-01.png')} className="img-fluid" alt="" />
                    </div>
                </div>
                                    
                <div className="col-lg-5 bg-content-3-container">
                    <h4 className="title1" style={{ fontWeight: 'bold', fontSize:'15pt' }}>Terima Kasih</h4> 
                    <p className="description" style={{ fontWeight: '500', color:'WHITE' }}>
                        Telah mendaftar sebagai NIEC Prioritas Member. Untuk bergbung sebagai NIEC Prioritas Member, silahkan melakukan pembayaran sebesar Rp {this.state.harga.harga} via transfer ke Bank Resmi NIEC Indonesia : 
                    </p>
                    <p style={{ fontWeight: 'bold', color:'WHITE' }}>
                    <br/>BANK BCA <br /> No rekening : 435.044.1234 <br />Atas nama : CV Naresy Indonesia
                    </p>
                    {uploadDiv}
                    <form onSubmit={e=>this.onFormSubmit(e)}  encType="multipart/form-data" >
                      <input type="hidden" value={this.state.id_member} ref={e=>this.inputKodeMember=e}/>
                      <h5 style={{color:'#fff',marginTop:'20px'}}>{uploadText} Bukti Pembayaran</h5>
                      <InputGroup className="form-group-no-border">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <input disabled={this.state.btnDisabled} type="file" className="form-control" onChange = {this.fileSelect} />
                      </InputGroup>
                      <Button block disabled={this.state.btnDisabled} className="btn-round bgcolorgold" type="submit" style={{marginTop:'30px'}}>
                        Upload
                      </Button>
                      <Row style={{marginBottom:"20px"}}>
                      <Col>
                        <div id="message" className={this.state.formAlertClassName+" alert-dismissible"} style={this.state.formAlert}>
                          {this.state.message}
                        </div>
                      </Col>
                    </Row>
                    </form>
                </div>
            </div>
        </Container>
      </div>{" "}
    </>
  );
      }
}

//export default InfoPayment;

const reduxState = (state) => ({
  dataUser: state.detailUser.user[0],
  idHarga: state.detailUser.user[0].id_harga
})

export default connect(reduxState)(InfoPayment);