import React, { Component } from "react";
import axios from 'axios';
import {Link} from 'react-router-dom';
//import api url
import API_URL from  './../../../ApiUrl';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import SweetAlert from 'sweetalert2-react';

import { LandingPageHeader, ExamplesNavbar, DemoFooter, CardInfo } from './../../../components/molecules/';
import { grey } from "ansi-colors";
import classnames from "classnames";
import Carousel from 'react-multi-carousel';



class Register extends Component {
  state = {
    nama_lengkap:"",
    no_telp:"",
    email:"",
    nama_sekolah:"",
    kelas_semester:"",
    konsultan:"0",
    password:"",
    re_password:"",
    message:"Error !",
    error: {},
    showSwal: false,
    formAlert:{display:'none'},
    formAlertClassName:'alert alert-danger',
    inputBorder: "border: 1px solid #ca0b0b",
    dataKonsultan:[]
  } 
  handleValidation(){
    let errors = {};
    let formIsValid = true;
    //nama
    if(!this.state.nama_lengkap){
        formIsValid = false;
        errors.nama_lengkap = "Nama wajib diisi";
        this.inputNamaLengkap.style = this.state.inputBorder;
    }
    //notelp
    if(!this.state.no_telp){
        formIsValid = false;
        errors.no_telp = "No. Telp wajib diisi";
        this.inputNoTelp.style = this.state.inputBorder;
    }
    //email
    if(!this.state.email){
        formIsValid = false;
        errors.email = "Email wajib diisi";
        this.inputEmail.style = this.state.inputBorder;
    }
    if(typeof this.state.email !== "undefined"){
        let lastAtPos  = this.state.email.lastIndexOf('@');
        let lastDotPos = this.state.email.lastIndexOf('.');
        if(!(lastAtPos < lastDotPos && lastAtPos > 0 && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)){
            formIsValid = false;
            errors.email = "Email tidak valid";  
            this.inputEmail.style = this.state.inputBorder;  
        }
    }
    //sekolah
    if(!this.state.nama_sekolah){
        formIsValid = false;
        errors.nama_sekolah = "Sekolah wajib diisi";
        this.inputNamaSekolah.style = this.state.inputBorder;
    }
    //kelas
    if(!this.state.kelas_semester){
        formIsValid = false;
        errors.kelas_semester = "Kelas/Semester wajib diisi";
        this.inputKelasSemester.style = this.state.inputBorder;
    }

    //konsultan
    if(this.state.konsultan === "0"){
      formIsValid = false;
      errors.konsultan = "Pilih konsultan terlebih dahulu";
      this.selectKonsultan.style = this.state.inputBorder;
    
    }
    //password
    if(!this.state.password){
      formIsValid = false;
      errors.password = "Password wajib diisi";
      this.inputPassword.style = this.state.inputBorder;
    }
    if(!this.state.re_password || this.state.re_password !== this.state.password){
      formIsValid = false;
      errors.re_password = "Password tidak sama";
      this.inputRePassword.style = this.state.inputBorder;  
    }
    this.setState({error:errors});
    return formIsValid;
  }

  registrationSubmit(e){
    e.preventDefault();
    if(this.handleValidation()){
      this.sendData();
    }
  }

  sendData(){
    var formData = new FormData();
    formData.append('nama_lengkap',this.state.nama_lengkap);
    formData.append('no_wa',this.state.no_telp);
    formData.append('email',this.state.email);
    formData.append('nama_sekolah',this.state.nama_sekolah);
    formData.append('kelas_semester',this.state.kelas_semester);
    formData.append('id_konsultan',this.state.konsultan);
    formData.append('password',this.state.password);
    axios({
      method: 'post',
      url:API_URL.url+'/member/registration',
      data:formData,
      config:{headers:{'Content-Type': 'multipart/form-data'}}
    }).then((reply)=>{
        //console.log(reply)
        if(reply.data === "ok"){
          this.setState({showSwal:true})
        }else{
          alert(reply);
        }
    }).catch(function(reply){
        console.log(reply)
    });
  }

  onBlurNamaLengkap(e){
    e.preventDefault()
    if(this.state.nama_lengkap.length > 2){
        this.inputNamaLengkap.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.nama_lengkap = '';                                 
            return { error };                   
        })
    }
  }
  onBlurNoTelp(e){
      e.preventDefault()
      if(this.state.no_telp.length > 0){
          this.inputNoTelp.style = "";
          this.setState(prevState => {
              let error = Object.assign({}, prevState.error);  
              error.no_telp = '';                                 
              return { error };                   
          })
      }else{
        this.inputNoTelp.style = this.state.inputBorder;
        this.setState(prevState => {
            let errors = Object.assign({}, prevState.error);  
            errors.no_telp = 'No. Telp Tidak boleh kosong';                                 
            this.setState({error:errors});                  
        })
      }
  }
  onBlurEmail(e){
      e.preventDefault()
      if(this.state.email.length > 5){
          this.inputEmail.style = "";
          this.setState(prevState => {
              let error = Object.assign({}, prevState.error);  
              error.email = '';                                 
              return { error };                   
          })
      }
  }
  onBlurNamaSekolah(e){
      e.preventDefault()
      if(this.state.nama_sekolah.length > 3){
          this.inputNamaSekolah.style = "";
          this.setState(prevState => {
              let error = Object.assign({}, prevState.error);  
              error.nama_sekolah = '';                                 
              return { error };                   
          })
      }
  }

  onBlurKonsultan(e){
    e.preventDefault()
    if(this.state.konsultan !== "0"){
        this.selectKonsultan.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.konsultan = '';                                 
            return { error };                   
        })
    }
  }
  onBlurKelasSemester(e){
      e.preventDefault()
      if(this.state.kelas_semester.length > 0){
          this.inputKelasSemester.style = "";
          this.setState(prevState => {
              let error = Object.assign({}, prevState.error);  
              error.kelas_semester = '';                                 
              return { error };                   
          })
      }
  }
  onBlurPassword(e){
    e.preventDefault()
    if(this.state.password.length > 0){
        this.inputPassword.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.password = '';                                 
            return { error };                   
        })
    }
  }
  onBlurRePassword(e){
    e.preventDefault()
    if(this.state.re_password === this.state.password){
        this.inputRePassword.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.re_password = '';                                 
            return { error };                   
        })
    }else{
      this.inputRePassword.style = this.state.inputBorder;
      this.setState(prevState => {
          let errors = Object.assign({}, prevState.error);  
          errors.re_password = 'Password tidak sama!';                                 
          this.setState({error:errors});                  
      })
    }
  }
  componentDidMount() {
    this.getDataKonsultan();
  }
  getDataKonsultan(){
    const url = API_URL.url+'/konsultan';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
        }
    }).then(response => response.data)
    .then((data) => {
        this.setState({ dataKonsultan: data })
        console.log(this.state.dataKonsultan)
    })
  }
  radioChangeHandler = (event) =>{
    this.setState({
        konsultan:event.target.value
    })
  }


  clearInput() {
    this.inputNamaLengkap.value = "";
    this.inputNoTelp.value = "";
    this.inputEmail.value = "";
    this.inputNamaSekolahlah.value = "";
    this.inputKelasSemester.value = "";
    this.inputPassword.value = "";
    this.selectKonsultan.value = "";
    this.inputRePassword.value = "";
  }

  render() {

  return (
    <>
      <ExamplesNavbar />
        <div className="section page-header section-dark" id="formRegis" style={{ backgroundColor: 'black' }}>
          <Container>
          <h2 className="title2 text-center">Registrasi Sekarang</h2>
          <form>
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <FormGroup>
                  <Label for="nama_lengkap" style={{ color: 'white', fontWeight:'500'}}>Nama Lengkap</Label>
                  <input className="form-control" type="nama_lengkap"  id="nama_lengkap" placeholder="Nama Lengkap"
                    onChange={(e) => this.setState({nama_lengkap:e.target.value})}
                    onBlur={(e) => this.onBlurNamaLengkap(e)}
                    ref={e => this.inputNamaLengkap = e}
                  />
                  <label className="error-label-register">
                      {this.state.error.nama_lengkap}
                  </label>
                </FormGroup>
                <FormGroup>
                  <Label for="inputEmail4" style={{ color: 'white', fontWeight:'500'}}>Email</Label>
                  <input className="form-control" type="email"  id="inputEmail4" placeholder="Email"
                    onChange={(e) => this.setState({email:e.target.value})}
                    onBlur={(e) => this.onBlurEmail(e)}
                    ref={e => this.inputEmail = e}
                  />
                  <label className="error-label-register">
                      {this.state.error.email}
                  </label>
                </FormGroup>
                <FormGroup>
                  <Label for="no_wa" style={{ color: 'white', fontWeight:'500'}}>No HP/WA</Label>
                  <input className="form-control" type="number"  id="no_wa" placeholder="No tlp / WhatsApp"
                    onChange={(e) => this.setState({no_telp:e.target.value})}
                    onBlur={(e) => this.onBlurNoTelp(e)}
                    ref={e => this.inputNoTelp = e}
                  />
                  <label className="error-label-register">
                      {this.state.error.no_telp}
                  </label>
                </FormGroup>
                <FormGroup>
                  <Label for="nama_sekolah" style={{ color: 'white', fontWeight:'500'}}>Nama Sekolah / Universitas</Label>
                  <input className="form-control" type="text"  id="nama_sekolah" placeholder="Nama Sekolah / Universitas"
                    onChange={(e) => this.setState({nama_sekolah:e.target.value})}
                    onBlur={(e) => this.onBlurNamaSekolah(e)}
                    ref={e => this.inputNamaSekolah = e}
                  />
                  <label className="error-label-register">
                      {this.state.error.nama_sekolah}
                  </label>
                </FormGroup>
                <FormGroup> 
                  <Label for="kelas_semester" style={{ color: 'white', fontWeight:'500'}}>Kelas / Semester</Label>
                  <input className="form-control" type="text"  id="kelas_semester" placeholder="Kelas / Semester"
                    onChange={(e) => this.setState({kelas_semester:e.target.value})}
                    onBlur={(e) => this.onBlurKelasSemester(e)}
                    ref={e => this.inputKelasSemester = e}
                  />
                  <label className="error-label-register">
                      {this.state.error.kelas_semester}
                  </label>
                </FormGroup>
                <FormGroup>
                  <Label for="konsultan" style={{color:'white', fontWeight:'500'}}>Konsultan</Label>
                  <select name="select" id="exampleSelect" className="form-control"
                    onChange={(e) => this.setState({konsultan:e.target.value})}
                    onBlur={(e) => this.onBlurKonsultan(e)}
                    ref={e => this.selectKonsultan = e}
                  >
                    <option value="0">--Pilih Konsultan--</option>
                    {this.state.dataKonsultan.map((data)=>(
                      <option key={data.id_konsultan} value={data.id_konsultan}>{data.nama_lengkap}, {data.nama_kota}</option>
                    ))}
                  </select>
                  <label className="error-label-register">
                      {this.state.error.konsultan}
                  </label>
                </FormGroup>
                <FormGroup>
                  <Label for="inputPassword4" style={{ color: 'white', fontWeight:'500'}}>Password</Label>
                  <input className="form-control" type="password"  id="inputPassword4" placeholder="Password" autoComplete="off"
                    onChange={(e) => this.setState({password:e.target.value})}
                    onBlur={(e) => this.onBlurPassword(e)}
                    ref={e => this.inputPassword = e}
                  />
                  <label className="error-label-register">
                      {this.state.error.password}
                  </label>
                </FormGroup>
                <FormGroup>
                  <Label for="inputRePassword4" style={{ color: 'white', fontWeight:'500'}}>Repeat Password</Label>
                  <input className="form-control" type="password"  id="inputRePassword4" placeholder="Password" autoComplete="off"
                    onChange={(e) => this.setState({re_password:e.target.value})}
                    onBlur={(e) => this.onBlurRePassword(e)}
                    ref={e => this.inputRePassword = e}
                  />
                  <label className="error-label-register">
                      {this.state.error.re_password}
                  </label>
                </FormGroup>
                  <Row>
                    <Col md="12">
                      <div id="message" className={this.state.formAlertClassName+" alert-dismissible"} style={this.state.formAlert}>
                          {this.state.message}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Button className="text-center" type="submit"  onClick={e=>this.registrationSubmit(e)} style={{ backgroundColor:'#C39B3F' }}>Kirim</Button>
                    </Col>
                  </Row>
              </Col>
              <SweetAlert
                show={this.state.showSwal}
                title="Selamat"
                text="Anda Berhasil Mendaftar"
                icon= 'success'
                confirmButtonText="Login"
                onConfirm={() => 
                  window.location.href="/login"
                }
            />
            </Row>
            
          </form>
          </Container>
        </div>
        </>  
  );
}
}

export default Register;
