import React,{Component} from 'react';
import { connect } from 'react-redux';
import {Modal, Row} from 'reactstrap';
class SplashScreen extends Component{
    intervalSplash;
    timeSplash = 2250;

    constructor(props){
        super(props);
        this.state = {
            isOpenSplashScreen:true,
            fadeSplashScreen:false,
        }
    }
    componentDidMount(){
        this.intervalSplash = setTimeout(this.CloseSplash,this.timeSplash);
    }
    CloseSplash = ()=>{
        this.setState({
            fadeSplashScreen:true,
            isOpenSplashScreen:false
        });
    }
    render(){
        return(
            <>
                {/* <div
                    isOpen={this.state.isOpenSplashScreen}
                    modalClassName="modal-splash-screen"
                    fade={this.state.fadeSplashScreen}
                >        */}
                <div className="content-center"> 
                    <Row>
                        <img src={require(`./../../../assets/img/niec-logo/Logo_Member-01.png`)} className="img-logo"/>
                    </Row>
                    <Row className="text-center">
                        <img src={require("../../../assets/img/DualBall.gif")} style={{width:'20%'}}/>
                        <h3 className="splash-screen-text">Loading ...</h3>
                    </Row>
                        
                </div>    
            </>
        )
    }
}
export default SplashScreen;
