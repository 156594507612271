/** @format */
import _LandingPage from './LandingPage';
import _Home from './Home';
import _Profile from './ProfilePage';
import _Login from './Login';
import _InfoPayment from './InfoPayment';
import _Logout from './Logout';
import _ChPassword from './ChPassword';

export const LandingPage = _LandingPage;
export const Home = _Home;
export const Profile = _Profile;
export const Login = _Login;
export const InfoPayment = _InfoPayment;
export const Logout = _Logout;
export const ChPassword = _ChPassword;
