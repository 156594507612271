/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React , { Component } from 'react';
import { connect } from 'react-redux';
import {
 Link
} from 'react-router-dom';
import axios from 'axios';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Modal
} from "reactstrap";
import classnames from "classnames";
import API_URL from '../../../ApiUrl';
import { updateDataProfile } from "./../../../config/redux/action"
import { VCard } from './../../../components/molecules';

// core components
class ProfileHeader extends Component {
  constructor(props){
    super(props);
    // let base64 = require('base-64');
    // if(!cookies.get('token')){
    //     window.location.href="/login";
    // }
    this.state = {
        dataRequest:[],
        //kodeMember: base64.decode(cookies.get('kode_member')),
        setLoginModal: false,
        ModalOpen_EditProfil: false,
        ModalOpen_MinatBakat: false,
        ModalOpen_ChangePassword: false,
        Modal_Kode: "",
        Modal_NamaLengkap: "",
        Modal_Email: "",
        Modal_NoWA: "", 
        Modal_Sekolah: "",
        Modal_Kelas: "",
        Modal_OldPassword: "",
        Modal_NewPassword: "",
        Modal_ConfirmNewPassword: "",
        error: {},
        inputBorder: "border:1px solid #ca0b0b;",
        dataMinatBakat: [],
        minat_bakat:"",
    }
}

componentDidMount(){
  this.getDataMinatBakat(); 
}

checkMinatBakat = () => {
  if(this.props.dataUser.id_minat_bakat === null){
      return(
        <div>
          <Button className="btn-sm" onClick={()=>this.ModalOpen_MinatBakat()} style={{fontSize:"7pt"}}>
            Pilih Minat & Bakat
          </Button>
          <Button className="btn-sm" onClick={()=>this.ModalOpen_EditProfil()} style={{marginLeft:"10px", fontSize:"7pt"}}>
            Edit Profil
          </Button>
          <Button className="btn-sm" onClick={()=>this.ModalOpen_ChangePassword()} style={{marginLeft:"10px", fontSize:"7pt"}}>
            Ganti Password
          </Button>
        </div>
      );
  }else{
    return(
      <div>
        <Button className="btn-sm" onClick={()=>this.ModalOpen_EditProfil()}>
          Edit Profil
        </Button>
        <Button className="btn-sm" onClick={()=>this.ModalOpen_ChangePassword()} style={{marginLeft:"10px"}}>
          Ganti Password
        </Button>
      </div>
    );
  }
}

getDataMinatBakat(){
  const url = API_URL.url+'/minatbakat';
  axios.get(url,{
      headers: {
          'Accept': 'application/json',
      }
  }).then(response => response.data)
  .then((data) => {
      this.setState({ dataMinatBakat: data })
      console.log( 'cek minatbakat', this.state.dataMinatBakat)
  })
}

onMinatBakatChanged = (e) => { 
  const id = e.currentTarget.value;
  setTimeout(
    function() {
      this.setState(prevState => {
        let minat_bakat = Object.assign({}, prevState.minat_bakat);  
        minat_bakat = id;                                 
        return { minat_bakat };                   
      })
      console.log('tes cange', this.state.minat_bakat)
    }
    .bind(this),
    1000
  );
}

onBlurMinatBakat(e){
  e.preventDefault()
  if(this.state.minat_bakat.length > 0){
    setTimeout(
      function() {
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.minat_bakat = '';                                 
            return { error };                   
        })
      }
      .bind(this),
      1000
    );
  }
} 


SaveEditedProfile = () =>{
  if(this.handleValidationChangedProfile()){
    const RootPath = API_URL.url;
    var email     = this.state.Modal_Email;
    var formData = new FormData();
    formData.append('_method', 'PUT'); 
    formData.append('nama_lengkap',this.state.Modal_NamaLengkap);
    formData.append('email',this.state.Modal_Email);
    formData.append('no_wa',this.state.Modal_NoWA);
    formData.append('nama_sekolah',this.state.Modal_Sekolah);
    formData.append('kelas_semester',this.state.Modal_Kelas);
    
   // API HERE
      axios({
          method: 'POST',
          url: RootPath+'/member/updprofile',
          data:formData,
          config:{headers:{'Content-Type':'application/x-www-form-urlencoded'}}
      }).then((reply) => {
          console.log(reply);
          if(reply.data.status === "SUCCESS_GNTPROFILE_200"){
            this.props.updateDataProfile(email)  
            // alert(reply.data.message);
            this.setState({
              ModalOpen_EditProfil: false
          });
          }else{
              alert(reply.data.message);
          }
      }).catch(function(reply){
          console.log(reply);
      })
  }
}

SaveChangedPassword = () => {
  if(this.handleValidationChangedPassword()){
      const RootPath = API_URL.url;        
      var email        = this.props.dataUser.email;
      var password     = this.state.Modal_OldPassword;
      var new_pass     = this.state.Modal_NewPassword;
    
      var formData = new FormData();
      formData.append('_method', 'PUT'); 
      formData.append('email',email);
      formData.append('password',password);
      formData.append('newpassword',new_pass);

      // API HERE
      axios({
          method: 'POST',
          url: RootPath+'/member/gantipassword',
          data:formData,
          config:{headers:{'Content-Type':'application/x-www-form-urlencoded'}}
      }).then((reply) => {
          console.log(reply);
          if(reply.data.status === "SUCCESS_GNTPASS_200"){
              
              alert('Password berhasil diubah\n Silahkan login kembali');
              window.location.href = '/logout';
          }else{
              alert('Password lama salah', reply.data.message);
              console.log(reply.data);
          }
      }).catch(function(reply){
          console.log(reply);
      })
  }
}

SaveMinatBakat = () => {
  if(this.handleValidationMinatBakat()){
      const RootPath = API_URL.url;        
      var id = this.props.dataUser.id_member;
      var email = this.props.dataUser.email;
    
      var formData = new FormData();
      formData.append('_method', 'PUT'); 
      formData.append('id_member',id);
      formData.append('id_minat_bakat',this.state.minat_bakat);

      // API HERE
      axios({
          method: 'POST',
          url: RootPath+'/insertminatbakat',
          data:formData,
          config:{headers:{'Content-Type':'application/x-www-form-urlencoded'}}
      }).then((reply) => {
          console.log('cek1',reply.data);
          if(reply.data.status === "200"){
            this.props.updateDataProfile(email)  
            alert(reply.data.message);
            this.setState({
              ModalOpen_MinatBakat: false
            });
          }else{
            alert(reply.data.message);
              // console.log('cek2',reply.data.message);
          }
      }).catch(function(reply){
          console.log(reply);
      })
  }
}

handleValidationChangedPassword(){
  let error = {};
  let formIsValid = true;

  //Password
  if(!this.state.Modal_OldPassword){
      formIsValid = false;
      error.OldPassword = "Password wajib diisi";
      this.inputOldPassword.style = this.state.inputBorder;
  }

 //Password
  if(!this.state.Modal_NewPassword){
      formIsValid = false;
      error.Password = "Password wajib diisi";
      this.inputPassword.style = this.state.inputBorder;
  }

  //re password
  if(!this.state.Modal_ConfirmNewPassword || this.state.Modal_NewPassword !== this.state.Modal_ConfirmNewPassword){
      formIsValid = false;
      error.re_password = "Password tidak sama";
      this.inputRePassword.style = this.state.inputBorder;  
  }

  this.setState({error:error});
  return formIsValid;
}
handleValidationMinatBakat(){
  let errors = {};
  let formIsValid = true;

  if(!this.state.minat_bakat){
    formIsValid = false;
    errors.minat_bakat = "Minat Bakat harus wajib dipilih";
    this.inputMinatBakat.style = this.state.inputBorder;
  }

  this.setState({error:errors});
  return formIsValid;
}

handleValidationChangedProfile(){
  let error = {};
  let formIsValid = true;

  if(!this.state.Modal_NamaLengkap){
    formIsValid = false;
    error.nama_lengkap = "Nama wajib diisi";
    this.inputNamaLengkap.style = this.state.inputBorder;
  }
  //notelp
  if(!this.state.Modal_NoWA){
      formIsValid = false;
      error.Modal_NoWA = "No. Telp wajib diisi";
      this.inputNoWA.style = this.state.inputBorder;
  }
  //sekolah
  if(!this.state.Modal_Sekolah){
      formIsValid = false;
      error.Modal_Sekolah = "Sekolah wajib diisi";
      this.inputSekolah.style = this.state.inputBorder;
  }
  //kelas
  if(!this.state.Modal_Kelas){
      formIsValid = false;
      error.Modal_Kelas = "Kelas/Semester wajib diisi";
      this.inputKelas.style = this.state.inputBorder;
  }

  this.setState({error:error});
  return formIsValid;
}

onBlurOldPassword(e){
  e.preventDefault()
  if(this.state.Modal_OldPassword.length > 2){
      this.inputOldPassword.style = "";
      this.setState(prevState => {
          let error = Object.assign({}, prevState.error);  
          error.OldPassword = '';                                 
          return { error };                   
      })
  }
}

onBlurPassword(e){
  e.preventDefault()
  if(this.state.Modal_NewPassword.length > 2){
      this.inputPassword.style = "";
      this.setState(prevState => {
          let error = Object.assign({}, prevState.error);  
          error.Password = '';                                 
          return { error };                   
      })
  }
}

onBlurRePassword(e){
  e.preventDefault()
  if(this.state.Modal_ConfirmNewPassword === this.state.Modal_NewPassword){
      this.inputRePassword.style = "";
      this.setState(prevState => {
          let error = Object.assign({}, prevState.error);  
          error.re_password = '';                                 
          return { error };                   
      })
  }else{
    this.inputRePassword.style = this.state.inputBorder;
    this.setState(prevState => {
        let errors = Object.assign({}, prevState.error);  
        errors.re_password = 'Password tidak sama!';                                 
        this.setState({error:errors});                  
    })
  }
}


ModalOpen_EditProfil = () => {
  const user = this.props.dataUser
  this.setState({
      Modal_Kode: user.id_member,
      Modal_NamaLengkap: user.nama_lengkap,
      Modal_Email: user.email,
      Modal_NoWA: user.no_wa,
      Modal_Sekolah: user.nama_sekolah,
      Modal_Kelas: user.kelas_semester,
      ModalOpen_EditProfil:true
  });
}

ModalOpen_MinatBakat = () => {
  const user = this.props.dataUser
  this.setState({
      ModalOpen_MinatBakat:true
  });
}

ModalOpen_ChangePassword = () => {
  this.setState({
      ModalOpen_ChangePassword:true
  });
}

ModalClose_EditProfil = () => {
  this.setState({
      ModalOpen_EditProfil: false
  });
}

ModalClose_MinatBakat = () => {
  this.setState({
      ModalOpen_MinatBakat: false
  });
}

ModalClose_ChangePassword = () => {
  this.setState({
      ModalOpen_ChangePassword: false
  });
}
  render() {
    return (
      <>
        <div
          style={{
            backgroundColor: "black"
          }}
          className="page-header section marginTop100"
          data-parallax={true}
          //ref={pageHeader}
        >
          <Container>
            {/* <div className="motto text-center">
              <img
                alt="logo-membership"
                className={classnames("membership-logo-motto")}
                src={require('./../../../assets/img/niec-logo/Logo_Member-01.png')}
              />
            </div> */}
              {/* <div className="row" style={{ paddingTop:'100px', backgroundColor:'red' }}>
                <div className="col" style={{ backgroundColor:'pink' }}>
                  <div classnames="row" style={{ backgroundColor:'green'}}>
                    <div className="col-lg-4 offset-lg-2 text-center bg-content-1-container" style={{ backgroundColor:'blue' }}>
                      <div className="bg-content-1">
                        <img src={require("./../../../assets/img/virtual-card/Virtual Card-01.png")} className="img-fluid" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-4 offset-lg-2 text-center bg-content-1-container" style={{ backgroundColor:'purple' }}>
                      <div className="bg-content-1">
                        <img src={require("./../../../assets/img/virtual-card/Virtual Card-01.png")} className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                  <div classnames="row" style={{ backgroundColor:'yellow' }}>
                    <div style={{ marginTop:'20px' }}>
                      <Button className="btn-round" style={{ color:'white' }} color="default" outline>Download Virtual Card</Button>
                    </div>
                  </div>
                </div>
              </div> */}
  
              <div className="row">
                  <div className="col-lg-4 offset-lg-2 bg-content-1-container" style={{paddingTop:"50px"}}>
                    <VCard />
                      {/* <div className="bg-content-1">
                          <img src={require('./../../../assets/img/virtual-card/Virtual Card-02.png')} className="img-fluid" alt="" />
                      </div> */}
                  </div>
                                      
                  <div className="col-lg-5 bg-content-3-container">
                      <h4 className="title1" style={{ fontWeight: 'bold', fontSize:'15pt' }}>Selamat datang di NIEC Prioritas member</h4> 
                      <p className="description" style={{ fontWeight: '500', color:'WHITE' }}>
                          Sebuah program keanggotaan yang bersifat ekslusif bagi siapapun yang memiliki keinginan 
                          untuk melanjutkan pendidikan keluar negeri. Dapatkan konsultasi berangkat keluar negeri 
                          dan berbagai akses ekslusif ke beberapa event NIEC Indonesia.
                      </p>
  
                      <div>
                          <table className="demo-table responsive" >
                              <tbody>
                                  <tr>
                                      <td data-header="Nama">{this.props.dataUser.nama_lengkap}</td>
                                      <td data-header="Email" >{this.props.dataUser.email}</td>
                                      <td data-header="No Telepon / Whatsapp" >{this.props.dataUser.no_wa}</td>
                                      <td data-header="Sekolah / Universitas" >{this.props.dataUser.nama_sekolah}</td>
                                      <td data-header="Kelas / Semester" >{this.props.dataUser.kelas_semester}</td>
                                      <td data-header="Kode Member" >{this.props.dataUser.kode_member}</td>
                                      <td data-header="Opsi">
                                        {this.checkMinatBakat()}
                                      </td>
                                  </tr>
                              </tbody>

                              <Modal
                                isOpen={this.state.ModalOpen_EditProfil}
                                toggle={this.ModalClose_EditProfil}
                                modalClassName="modal-register"
                                                    >
                                <div className="modal-header no-border-header text-center">
                                    <h3 className="header-detail-transaksi">Edit Profil</h3>
                                    <button
                                        aria-label="Close"
                                        className="close"
                                        data-dismiss="modal"
                                        type="button"
                                        onClick={this.ModalClose_EditProfil}
                                    >
                                        <span aria-hidden={true}>×</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{padding:'10px'}}>
                                    <div className="form-row">
                                        {/*}
                                        <FormGroup className="col-md-6">     
                                            <label className="text-muted" style={{ fontSize:"10pt", color:"black", fontWeight: "bolder" }}>Email</label>   
                                            <input type="email" className="form-control" placeholder="" autoComplete="off"
                                                onChange={(e) => this.setState({Modal_Email:e.target.value})}
                                                value={this.state.Modal_Email}
                                                required
                                            />
                                        </FormGroup>
                                        {*/}

                                        <FormGroup className="col-md-12">     
                                            <label className="text-muted" style={{ fontSize:"10pt", color:"black", fontWeight: "bolder" }}>Nama</label>   
                                            <input className="form-control" placeholder="" autoComplete="off"
                                                onChange={(e) => this.setState({Modal_NamaLengkap:e.target.value})}
                                                value={this.state.Modal_NamaLengkap}
                                                ref={e => this.inputNamaLengkap = e}
                                                required
                                            />
                                            <label className="error-label-register">
                                                {this.state.error.Modal_NamaLengkap}
                                            </label>
                                        </FormGroup>   

                                        <FormGroup className="col-md-12">     
                                            <label className="text-muted" style={{ fontSize:"10pt", color:"black", fontWeight: "bolder" }}>Email</label>   
                                            <input className="form-control" placeholder="" autoComplete="off"
                                                onChange={(e) => this.setState({Modal_Email:e.target.value})}
                                                value={this.state.Modal_Email}
                                                disabled
                                            />
                                        </FormGroup> 

                                        <FormGroup className="col-md-12">     
                                            <label className="text-muted" style={{ fontSize:"10pt", color:"black", fontWeight: "bolder" }}>No Telp / WA</label>   
                                            <input type="email" className="form-control" placeholder="" autoComplete="off"
                                                onChange={(e) => this.setState({Modal_NoWA:e.target.value})}
                                                value={this.state.Modal_NoWA}
                                                ref={e => this.inputNoWa = e}
                                                required
                                            />
                                            <label className="error-label-register">
                                                {this.state.error.Modal_NoWA}
                                            </label>
                                        </FormGroup>

                                        <FormGroup className="col-md-12">     
                                            <label className="text-muted" style={{ fontSize:"10pt", color:"black", fontWeight: "bolder" }}>Sekolah / Universitas</label>   
                                            <input className="form-control" placeholder="" autoComplete="off"
                                                onChange={(e) => this.setState({Modal_Sekolah:e.target.value})}
                                                value={this.state.Modal_Sekolah}
                                                ref={e => this.inputSekolah = e}
                                                required
                                            />
                                            <label className="error-label-register">
                                                {this.state.error.Modal_Sekolah}
                                            </label>
                                        </FormGroup>
                                        
                                        <FormGroup className="col-md-12">     
                                            <label className="text-muted" style={{ fontSize:"10pt", color:"black", fontWeight: "bolder" }}>Kelas / Semester</label>   
                                            <input className="form-control" placeholder="" autoComplete="off"
                                                onChange={(e) => this.setState({Modal_Kelas:e.target.value})}
                                                value={this.state.Modal_Kelas}
                                                ref={e => this.inputKelas = e}
                                                required
                                            />
                                            <label className="error-label-register">
                                                {this.state.error.Modal_Kelas}
                                            </label>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="modal-footer no-border-footer" style={{padding: '10px'}}>
                                <Button onClick={()=>this.SaveEditedProfile()} block 
                                className="btn-round bgcolorgold" style={{marginTop:'5px', backgroundColor:"#E9BD43", border:"none"}}>
                                    SIMPAN
                                </Button>
                                </div>
                            </Modal>

                            <Modal
                                isOpen={this.state.ModalOpen_ChangePassword}
                                toggle={this.ModalClose_ChangePassword}
                                modalClassName="modal-register"
                            >
                                <div className="modal-header no-border-header text-center">
                                    <h3 className="header-detail-transaksi">Ganti Password</h3>
                                    <button
                                        aria-label="Close"
                                        className="close"
                                        data-dismiss="modal"
                                        type="button"
                                        onClick={this.ModalClose_ChangePassword}
                                    >
                                        <span aria-hidden={true}>×</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{padding:'10px'}}>
                                    <div className="form-row">
                                        <FormGroup className="col-md-12">     
                                            <label className="text-muted" style={{ fontSize:"10pt", color:"black", fontWeight: "bolder" }}>Password Lama</label>   
                                            <input type="password" className="form-control" placeholder="" autoComplete="off"
                                                onChange={(e) => this.setState({Modal_OldPassword:e.target.value})}     
                                                onBlur={(e) => this.onBlurOldPassword(e)}
                                                ref={e => this.inputOldPassword = e}
                                            />
                                            <label className="error-label-register">
                                                {this.state.error.OldPassword}
                                            </label>
                                        </FormGroup>
                                    </div>
                                    <div className="form-row">
                                        <FormGroup className="col-md-12">     
                                            <label className="text-muted" style={{ fontSize:"10pt", color:"black", fontWeight: "bolder" }}>Password Baru</label>   
                                            <input type="password" className="form-control" placeholder="" autoComplete="off"
                                                onChange={(e) => this.setState({Modal_NewPassword:e.target.value})}     
                                                onBlur={(e) => this.onBlurPassword(e)}
                                                ref={e => this.inputPassword = e}
                                            />
                                            <label className="error-label-register">
                                                {this.state.error.Password}
                                            </label>
                                        </FormGroup>
                                        <FormGroup className="col-md-12">     
                                            <label className="text-muted" style={{ fontSize:"10pt", color:"black", fontWeight: "bolder" }}>Ketik Ulang Password Baru</label>   
                                            <input type="password" className="form-control" placeholder="" autoComplete="off"
                                                onChange={(e) => this.setState({Modal_ConfirmNewPassword:e.target.value})}
                                                onBlur={(e) => this.onBlurRePassword(e)}
                                                ref={e => this.inputRePassword = e}
                                            />
                                            <label className="error-label-register">
                                                {this.state.error.re_password}
                                            </label>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="modal-footer no-border-footer" style={{padding: '10px'}}>
                                <Button onClick={()=>this.SaveChangedPassword()} block
                                className="btn-round bgcolorgold" style={{marginTop:'5px', backgroundColor:"#E9BD43", border:"none"}}>
                                    SIMPAN
                                </Button>
                                </div>
                            </Modal>

                            <Modal
                                isOpen={this.state.ModalOpen_MinatBakat}
                                toggle={this.ModalClose_MinatBakat}
                                modalClassName="modal-register"
                                style={{width:"900px"}}
                            >
                                <div className="modal-header no-border-header text-center">
                                    <h3 className="header-detail-transaksi">Pilih Data Minat dan Bakat</h3>
                                    <button
                                        aria-label="Close"
                                        className="close"
                                        data-dismiss="modal"
                                        type="button"
                                        onClick={this.ModalClose_MinatBakat}
                                    >
                                        <span aria-hidden={true}>×</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{paddingRight:'100px'}}>
                                    <div className="form-row">
                                      <Col lg="12" md="12">
                                        <Label style={{ color: 'white', fontWeight:'bold', marginBottom:"20px"}}>Pilihlah Salah Satu Minat Bakat di bawah ini :</Label>
                                          <div ref={e => this.inputMinatBakat = e}>
                                            {this.state.dataMinatBakat.map(minatBakat => 
                                              {
                                                return(
                                                  <FormGroup check>   
                                                    <Label check style={{ color: 'black', fontWeight:'500'}}>
                                                      <Row style={{backgroundColor:"", border:"1 white"}}>
                                                        <Col md="1" style={{display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                          
                                                              <input
                                                                id="minat_bakat"
                                                                value={minatBakat.id_minat_bakat} 
                                                                type="radio"
                                                                name="minat_bakat"
                                                                onChange={this.onMinatBakatChanged}
                                                                onBlur={(e) => this.onBlurMinatBakat(e)}
                                                                // ref={e => this.inputMinatBakat = e}
                                                              />
                                                        </Col>
                                                        <Col style={{padding:"20px", backgroundColor:""}}>
                                                            <Row>                                       
                                                                <legend style={{ marginBottom:"0px", marginTop:"0px",color: 'black', fontWeight:'bold', textAlign:"left", fontSize:"11pt"}}>{minatBakat.minat}</legend>
                                                            </Row>
                                                            <Row>                                       
                                                                <legend style={{ marginBottom:"0px", marginTop:"0px",color: 'black', fontWeight:'500', textAlign:"left", fontSize:"11pt"}}>{minatBakat.deskripsi}</legend>
                                                            </Row>
                                                        </Col>
                                                      </Row>
                                                    </Label>
                                                  </FormGroup>
                                                );
                                              })
                                            }
                                          </div>
                                          <label className="error-label-register">
                                            {this.state.error.minat_bakat}
                                          </label>
                                      </Col>
                                    </div>
                                </div>
                                <div className="modal-footer no-border-footer" style={{padding: '10px'}}>
                                <Button onClick={()=>this.SaveMinatBakat()} block
                                className="btn-round bgcolorgold" style={{marginTop:'5px', backgroundColor:"#E9BD43", border:"none"}}>
                                    SIMPAN
                                </Button>
                                </div>
                            </Modal>

                          </table>
  
  {/* 
                        <tr>
                          <td style={{ width:"120px", textAlign:"left" }}>Nama</td>
                          <td>:</td>
                          <td style={{ textAlign:"left" }}>Nama Member</td>
                        </tr>
                        <tr>
                          <td style={{ width:"120px", textAlign:"left" }}>Email</td>
                          <td>:</td>
                          <td style={{ textAlign:"left" }}>email@member.com</td>
                        </tr>
                        <tr>
                          <td style={{ width:"120px" , textAlign:"left"}}>Telepon</td>
                          <td>:</td>
                          <td style={{ textAlign:"left" }}>081222333444</td>
                        </tr>
                        <tr>
                          <td style={{ width:"120px", textAlign:"left" }}>Kode Member</td>
                          <td>:</td>
                          <td style={{ textAlign:"left" }}>M000001</td>
                        </tr> */}
                      </div>
                  </div>
              </div>
              
              
              {/* <div className="row" style={{ paddingTop:'100px', backgroundColor:'red' }}>
                <div classnames="col">
                  <div classnames="row">
                    <div className="col-lg-4 offset-lg-2 text-center bg-content-1-container" style={{ backgroundColor:'blue' }}>
                      <div className="bg-content-1">
                        <img src={require("./../../../assets/img/virtual-card/Virtual Card-01.png")} className="img-fluid" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-4 text-center bg-content-1-container" style={{ backgroundColor:'purple' }}>
                      <div className="bg-content-1">
                        <img src={require("./../../../assets/img/virtual-card/Virtual Card-01.png")} className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>  */}
          </Container>
        </div>
      </>
    );
  }
  
}

//export default ProfileHeader;

const reduxState = (state) => ({
  dataUser: state.detailUser.user[0]
})

const reduxDispatch = (dispatch) => ({
  updateDataProfile: (data) => dispatch(updateDataProfile(data))
 })

export default connect(reduxState, reduxDispatch)(ProfileHeader);